/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AboutBlocksDynamicZoneInput: { input: any; output: any };
  ActivityLinkedItemsDynamicZoneInput: { input: any; output: any };
  ActivitySectionsDynamicZoneInput: { input: any; output: any };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any };
  /** A string used to identify an i18n locale */
  I18NLocaleCode: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  LessonLinkedItemsDynamicZoneInput: { input: any; output: any };
  LessonSectionsDynamicZoneInput: { input: any; output: any };
  PageBlocksDynamicZoneInput: { input: any; output: any };
  ProjectLinkedItemsDynamicZoneInput: { input: any; output: any };
  ProjectSectionsDynamicZoneInput: { input: any; output: any };
  ProjectTableOfContentsDynamicZoneInput: { input: any; output: any };
  ResourceLinkedItemsDynamicZoneInput: { input: any; output: any };
  ResourceSectionsDynamicZoneInput: { input: any; output: any };
  RoomAllowedFiltersDynamicZoneInput: { input: any; output: any };
  TutorialStepsDynamicZoneInput: { input: any; output: any };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any };
};

export type A1 = {
  __typename?: 'A1';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type A1Entity = {
  __typename?: 'A1Entity';
  attributes?: Maybe<A1>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type A1EntityResponse = {
  __typename?: 'A1EntityResponse';
  data?: Maybe<A1Entity>;
};

export type A1Input = {
  greeting?: InputMaybe<Scalars['JSON']['input']>;
};

export type About = {
  __typename?: 'About';
  blocks?: Maybe<Array<Maybe<AboutBlocksDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AboutBlocksDynamicZone =
  | ComponentSharedMedia
  | ComponentSharedQuote
  | ComponentSharedRichText
  | ComponentSharedSlider
  | Error;

export type AboutEntity = {
  __typename?: 'AboutEntity';
  attributes?: Maybe<About>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type AboutEntityResponse = {
  __typename?: 'AboutEntityResponse';
  data?: Maybe<AboutEntity>;
};

export type AboutInput = {
  blocks?: InputMaybe<Array<Scalars['AboutBlocksDynamicZoneInput']['input']>>;
  tag?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type Activity = {
  __typename?: 'Activity';
  complexity?: Maybe<ComplexityEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  gallery?: Maybe<ComponentSharedGallery>;
  goals?: Maybe<Array<Maybe<ComponentContentLearningGoals>>>;
  grades?: Maybe<GradeRelationResponseCollection>;
  learningDuration?: Maybe<ComponentAtomsTimeSelector>;
  level?: Maybe<ComponentContentLevel>;
  linkedItems?: Maybe<Array<Maybe<ActivityLinkedItemsDynamicZone>>>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<ActivityRelationResponseCollection>;
  materials?: Maybe<Array<Maybe<ComponentContentRequireMaterials>>>;
  name: Scalars['String']['output'];
  ordering?: Maybe<Scalars['Int']['output']>;
  preparationDuration?: Maybe<ComponentAtomsTimeSelector>;
  preview?: Maybe<UploadFileEntityResponse>;
  project?: Maybe<ProjectEntityResponse>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  rooms?: Maybe<RoomRelationResponseCollection>;
  sections?: Maybe<Array<Maybe<ActivitySectionsDynamicZone>>>;
  slug: Scalars['String']['output'];
  subjects?: Maybe<SubjectRelationResponseCollection>;
  subscriptions?: Maybe<SubscriptionRelationResponseCollection>;
  tags?: Maybe<TagRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ActivityGoalsArgs = {
  filters?: InputMaybe<ComponentContentLearningGoalsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ActivityGradesArgs = {
  filters?: InputMaybe<GradeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ActivityLocalizationsArgs = {
  filters?: InputMaybe<ActivityFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ActivityMaterialsArgs = {
  filters?: InputMaybe<ComponentContentRequireMaterialsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ActivityRoomsArgs = {
  filters?: InputMaybe<RoomFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ActivitySubjectsArgs = {
  filters?: InputMaybe<SubjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ActivitySubscriptionsArgs = {
  filters?: InputMaybe<SubscriptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ActivityTagsArgs = {
  filters?: InputMaybe<TagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ActivityEntity = {
  __typename?: 'ActivityEntity';
  attributes?: Maybe<Activity>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ActivityEntityResponse = {
  __typename?: 'ActivityEntityResponse';
  data?: Maybe<ActivityEntity>;
};

export type ActivityEntityResponseCollection = {
  __typename?: 'ActivityEntityResponseCollection';
  data: Array<ActivityEntity>;
  meta: ResponseCollectionMeta;
};

export type ActivityFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ActivityFiltersInput>>>;
  complexity?: InputMaybe<ComplexityFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  gallery?: InputMaybe<ComponentSharedGalleryFiltersInput>;
  goals?: InputMaybe<ComponentContentLearningGoalsFiltersInput>;
  grades?: InputMaybe<GradeFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  learningDuration?: InputMaybe<ComponentAtomsTimeSelectorFiltersInput>;
  level?: InputMaybe<ComponentContentLevelFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<ActivityFiltersInput>;
  materials?: InputMaybe<ComponentContentRequireMaterialsFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ActivityFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ActivityFiltersInput>>>;
  ordering?: InputMaybe<IntFilterInput>;
  preparationDuration?: InputMaybe<ComponentAtomsTimeSelectorFiltersInput>;
  project?: InputMaybe<ProjectFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  rooms?: InputMaybe<RoomFiltersInput>;
  slug?: InputMaybe<StringFilterInput>;
  subjects?: InputMaybe<SubjectFiltersInput>;
  subscriptions?: InputMaybe<SubscriptionFiltersInput>;
  tags?: InputMaybe<TagFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ActivityInput = {
  complexity?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  gallery?: InputMaybe<ComponentSharedGalleryInput>;
  goals?: InputMaybe<Array<InputMaybe<ComponentContentLearningGoalsInput>>>;
  grades?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  learningDuration?: InputMaybe<ComponentAtomsTimeSelectorInput>;
  level?: InputMaybe<ComponentContentLevelInput>;
  linkedItems?: InputMaybe<Array<Scalars['ActivityLinkedItemsDynamicZoneInput']['input']>>;
  materials?: InputMaybe<Array<InputMaybe<ComponentContentRequireMaterialsInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  ordering?: InputMaybe<Scalars['Int']['input']>;
  preparationDuration?: InputMaybe<ComponentAtomsTimeSelectorInput>;
  preview?: InputMaybe<Scalars['ID']['input']>;
  project?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  rooms?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  sections?: InputMaybe<Array<Scalars['ActivitySectionsDynamicZoneInput']['input']>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  subscriptions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ActivityLinkedItemsDynamicZone =
  | ComponentItemsLinkedChallenges
  | ComponentItemsLinkedLessons
  | ComponentItemsLinkedProjects
  | Error;

export type ActivityRelationResponseCollection = {
  __typename?: 'ActivityRelationResponseCollection';
  data: Array<ActivityEntity>;
};

export type ActivitySectionsDynamicZone = ComponentSectionsCommonSection | Error;

export type AppSettings = {
  __typename?: 'AppSettings';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  key: Scalars['String']['output'];
  settings?: Maybe<Array<Maybe<ComponentItemsSetting>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AppSettingsSettingsArgs = {
  filters?: InputMaybe<ComponentItemsSettingFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AppSettingsEntity = {
  __typename?: 'AppSettingsEntity';
  attributes?: Maybe<AppSettings>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type AppSettingsEntityResponse = {
  __typename?: 'AppSettingsEntityResponse';
  data?: Maybe<AppSettingsEntity>;
};

export type AppSettingsEntityResponseCollection = {
  __typename?: 'AppSettingsEntityResponseCollection';
  data: Array<AppSettingsEntity>;
  meta: ResponseCollectionMeta;
};

export type AppSettingsFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<AppSettingsFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  key?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<AppSettingsFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<AppSettingsFiltersInput>>>;
  settings?: InputMaybe<ComponentItemsSettingFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type AppSettingsInput = {
  key?: InputMaybe<Scalars['String']['input']>;
  settings?: InputMaybe<Array<InputMaybe<ComponentItemsSettingInput>>>;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  contains?: InputMaybe<Scalars['Boolean']['input']>;
  containsi?: InputMaybe<Scalars['Boolean']['input']>;
  endsWith?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  eqi?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  nei?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']['input']>;
  notContainsi?: InputMaybe<Scalars['Boolean']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Challenge = {
  __typename?: 'Challenge';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<ChallengeRelationResponseCollection>;
  name: Scalars['String']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  rooms?: Maybe<RoomRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ChallengeLocalizationsArgs = {
  filters?: InputMaybe<ChallengeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ChallengeRoomsArgs = {
  filters?: InputMaybe<RoomFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ChallengeEntity = {
  __typename?: 'ChallengeEntity';
  attributes?: Maybe<Challenge>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ChallengeEntityResponse = {
  __typename?: 'ChallengeEntityResponse';
  data?: Maybe<ChallengeEntity>;
};

export type ChallengeEntityResponseCollection = {
  __typename?: 'ChallengeEntityResponseCollection';
  data: Array<ChallengeEntity>;
  meta: ResponseCollectionMeta;
};

export type ChallengeFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ChallengeFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<ChallengeFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ChallengeFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ChallengeFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  rooms?: InputMaybe<RoomFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ChallengeInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  rooms?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ChallengeRelationResponseCollection = {
  __typename?: 'ChallengeRelationResponseCollection';
  data: Array<ChallengeEntity>;
};

export type Complexity = {
  __typename?: 'Complexity';
  activities?: Maybe<ActivityRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lessons?: Maybe<LessonRelationResponseCollection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<ComplexityRelationResponseCollection>;
  name: Scalars['String']['output'];
  ordering: Scalars['Int']['output'];
  projects?: Maybe<ProjectRelationResponseCollection>;
  resources?: Maybe<ResourceRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ComplexityActivitiesArgs = {
  filters?: InputMaybe<ActivityFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComplexityLessonsArgs = {
  filters?: InputMaybe<LessonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComplexityLocalizationsArgs = {
  filters?: InputMaybe<ComplexityFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComplexityProjectsArgs = {
  filters?: InputMaybe<ProjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComplexityResourcesArgs = {
  filters?: InputMaybe<ResourceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComplexityEntity = {
  __typename?: 'ComplexityEntity';
  attributes?: Maybe<Complexity>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ComplexityEntityResponse = {
  __typename?: 'ComplexityEntityResponse';
  data?: Maybe<ComplexityEntity>;
};

export type ComplexityEntityResponseCollection = {
  __typename?: 'ComplexityEntityResponseCollection';
  data: Array<ComplexityEntity>;
  meta: ResponseCollectionMeta;
};

export type ComplexityFiltersInput = {
  activities?: InputMaybe<ActivityFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<ComplexityFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  lessons?: InputMaybe<LessonFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<ComplexityFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComplexityFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComplexityFiltersInput>>>;
  ordering?: InputMaybe<IntFilterInput>;
  projects?: InputMaybe<ProjectFiltersInput>;
  resources?: InputMaybe<ResourceFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ComplexityInput = {
  activities?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lessons?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  ordering?: InputMaybe<Scalars['Int']['input']>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  resources?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ComplexityRelationResponseCollection = {
  __typename?: 'ComplexityRelationResponseCollection';
  data: Array<ComplexityEntity>;
};

export type ComponentAtomsAction = {
  __typename?: 'ComponentAtomsAction';
  action: Scalars['String']['output'];
  actionTitle: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentAtomsActionInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  actionTitle?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentAtomsItem = {
  __typename?: 'ComponentAtomsItem';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentAtomsLinkableList = {
  __typename?: 'ComponentAtomsLinkableList';
  anchor?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  link?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentAtomsLinkableListFiltersInput = {
  anchor?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentAtomsLinkableListFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  link?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentAtomsLinkableListFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentAtomsLinkableListFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentAtomsList = {
  __typename?: 'ComponentAtomsList';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type ComponentAtomsTextWithTime = {
  __typename?: 'ComponentAtomsTextWithTime';
  hours?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  link?: Maybe<Scalars['String']['output']>;
  minutes?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentAtomsTextWithTimeFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentAtomsTextWithTimeFiltersInput>>>;
  hours?: InputMaybe<IntFilterInput>;
  link?: InputMaybe<StringFilterInput>;
  minutes?: InputMaybe<IntFilterInput>;
  not?: InputMaybe<ComponentAtomsTextWithTimeFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentAtomsTextWithTimeFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
};

export type ComponentAtomsTimeSelector = {
  __typename?: 'ComponentAtomsTimeSelector';
  hours?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  minutes?: Maybe<Scalars['Int']['output']>;
};

export type ComponentAtomsTimeSelectorFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentAtomsTimeSelectorFiltersInput>>>;
  hours?: InputMaybe<IntFilterInput>;
  minutes?: InputMaybe<IntFilterInput>;
  not?: InputMaybe<ComponentAtomsTimeSelectorFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentAtomsTimeSelectorFiltersInput>>>;
};

export type ComponentAtomsTimeSelectorInput = {
  hours?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  minutes?: InputMaybe<Scalars['Int']['input']>;
};

export type ComponentBusinessRelatedSubscription = {
  __typename?: 'ComponentBusinessRelatedSubscription';
  active?: Maybe<Scalars['Boolean']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  subscription?: Maybe<SubscriptionEntityResponse>;
};

export type ComponentBusinessRelatedSubscriptionFiltersInput = {
  active?: InputMaybe<BooleanFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentBusinessRelatedSubscriptionFiltersInput>>>;
  endDate?: InputMaybe<DateTimeFilterInput>;
  not?: InputMaybe<ComponentBusinessRelatedSubscriptionFiltersInput>;
  notes?: InputMaybe<StringFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentBusinessRelatedSubscriptionFiltersInput>>>;
  startDate?: InputMaybe<DateTimeFilterInput>;
  subscription?: InputMaybe<SubscriptionFiltersInput>;
};

export type ComponentBusinessRelatedSubscriptionInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  subscription?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentBusinessSchoolRole = {
  __typename?: 'ComponentBusinessSchoolRole';
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  role: Enum_Componentbusinessschoolrole_Role;
  school?: Maybe<SchoolEntityResponse>;
};

export type ComponentBusinessSchoolRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentBusinessSchoolRoleFiltersInput>>>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<ComponentBusinessSchoolRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentBusinessSchoolRoleFiltersInput>>>;
  role?: InputMaybe<StringFilterInput>;
  school?: InputMaybe<SchoolFiltersInput>;
};

export type ComponentBusinessSchoolRoleInput = {
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  role?: InputMaybe<Enum_Componentbusinessschoolrole_Role>;
  school?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentContentLearningGoals = {
  __typename?: 'ComponentContentLearningGoals';
  body: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ComponentContentLearningGoalsFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentContentLearningGoalsFiltersInput>>>;
  body?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentContentLearningGoalsFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentContentLearningGoalsFiltersInput>>>;
};

export type ComponentContentLearningGoalsInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentContentLevel = {
  __typename?: 'ComponentContentLevel';
  body?: Maybe<Enum_Componentcontentlevel_Body>;
  id: Scalars['ID']['output'];
};

export type ComponentContentLevelFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentContentLevelFiltersInput>>>;
  body?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentContentLevelFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentContentLevelFiltersInput>>>;
};

export type ComponentContentLevelInput = {
  body?: InputMaybe<Enum_Componentcontentlevel_Body>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentContentListByTag = {
  __typename?: 'ComponentContentListByTag';
  id: Scalars['ID']['output'];
  limit?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  tag?: Maybe<TagEntityResponse>;
};

export type ComponentContentListByTagFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentContentListByTagFiltersInput>>>;
  limit?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentContentListByTagFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentContentListByTagFiltersInput>>>;
  tag?: InputMaybe<TagFiltersInput>;
};

export type ComponentContentListByTagInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentContentRequireMaterials = {
  __typename?: 'ComponentContentRequireMaterials';
  body: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ComponentContentRequireMaterialsFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentContentRequireMaterialsFiltersInput>>>;
  body?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentContentRequireMaterialsFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentContentRequireMaterialsFiltersInput>>>;
};

export type ComponentContentRequireMaterialsInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentDashboardCollections = {
  __typename?: 'ComponentDashboardCollections';
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  tags: Array<Maybe<ComponentContentListByTag>>;
  title: Scalars['String']['output'];
};

export type ComponentDashboardCollectionsTagsArgs = {
  filters?: InputMaybe<ComponentContentListByTagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentDashboardCollectionsInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  tags?: InputMaybe<Array<InputMaybe<ComponentContentListByTagInput>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentDashboardGreetingSection = {
  __typename?: 'ComponentDashboardGreetingSection';
  communityAction: ComponentAtomsAction;
  content?: Maybe<Scalars['String']['output']>;
  helpAction: ComponentAtomsAction;
  id: Scalars['ID']['output'];
  libraryAction: ComponentAtomsAction;
  pdAction: ComponentAtomsAction;
  title: Scalars['String']['output'];
};

export type ComponentDashboardGreetingSectionInput = {
  communityAction?: InputMaybe<ComponentAtomsActionInput>;
  content?: InputMaybe<Scalars['String']['input']>;
  helpAction?: InputMaybe<ComponentAtomsActionInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  libraryAction?: InputMaybe<ComponentAtomsActionInput>;
  pdAction?: InputMaybe<ComponentAtomsActionInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentDashboardInspirationSection = {
  __typename?: 'ComponentDashboardInspirationSection';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inspirationAction?: Maybe<ComponentAtomsAction>;
  title: Scalars['String']['output'];
};

export type ComponentDashboardInspirationSectionInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inspirationAction?: InputMaybe<ComponentAtomsActionInput>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentFiltersComplexityFilter = {
  __typename?: 'ComponentFiltersComplexityFilter';
  complexities?: Maybe<ComplexityRelationResponseCollection>;
  editable?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  multiple?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentFiltersComplexityFilterComplexitiesArgs = {
  filters?: InputMaybe<ComplexityFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFiltersContentTypeFilter = {
  __typename?: 'ComponentFiltersContentTypeFilter';
  editable?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  multiple?: Maybe<Scalars['Boolean']['output']>;
  showActivities?: Maybe<Scalars['Boolean']['output']>;
  showChallenges?: Maybe<Scalars['Boolean']['output']>;
  showLessons?: Maybe<Scalars['Boolean']['output']>;
  showProjects?: Maybe<Scalars['Boolean']['output']>;
  showResources?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentFiltersDurationFilter = {
  __typename?: 'ComponentFiltersDurationFilter';
  durations: Array<Maybe<ComponentHelpersDurationFilterTimeSelector>>;
  editable?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  multiple?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentFiltersDurationFilterDurationsArgs = {
  filters?: InputMaybe<ComponentHelpersDurationFilterTimeSelectorFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFiltersFavoriteFilter = {
  __typename?: 'ComponentFiltersFavoriteFilter';
  editable?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  multiple?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentFiltersGradesFilter = {
  __typename?: 'ComponentFiltersGradesFilter';
  editable?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  grades?: Maybe<GradeRelationResponseCollection>;
  id: Scalars['ID']['output'];
  multiple?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentFiltersGradesFilterGradesArgs = {
  filters?: InputMaybe<GradeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFiltersOwnerFilter = {
  __typename?: 'ComponentFiltersOwnerFilter';
  editable?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  multiple?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentFiltersSharedFilter = {
  __typename?: 'ComponentFiltersSharedFilter';
  editable?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  multiple?: Maybe<Scalars['Boolean']['output']>;
  showSharedByMe?: Maybe<Scalars['Boolean']['output']>;
  showSharedWithMe?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentFiltersSubjectsFilter = {
  __typename?: 'ComponentFiltersSubjectsFilter';
  editable?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  multiple?: Maybe<Scalars['Boolean']['output']>;
  subjects?: Maybe<SubjectRelationResponseCollection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentFiltersSubjectsFilterSubjectsArgs = {
  filters?: InputMaybe<SubjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFiltersSubscriptionsFilter = {
  __typename?: 'ComponentFiltersSubscriptionsFilter';
  editable?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  multiple?: Maybe<Scalars['Boolean']['output']>;
  subscriptions?: Maybe<SubscriptionRelationResponseCollection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentFiltersSubscriptionsFilterSubscriptionsArgs = {
  filters?: InputMaybe<SubscriptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentFiltersTagsFilter = {
  __typename?: 'ComponentFiltersTagsFilter';
  editable?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  multiple?: Maybe<Scalars['Boolean']['output']>;
  tags?: Maybe<TagRelationResponseCollection>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentFiltersTagsFilterTagsArgs = {
  filters?: InputMaybe<TagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentHelpersDurationFilterTimeSelector = {
  __typename?: 'ComponentHelpersDurationFilterTimeSelector';
  fromHours?: Maybe<Scalars['Int']['output']>;
  fromMinutes: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  toHours: Scalars['Int']['output'];
  toMinutes: Scalars['Int']['output'];
};

export type ComponentHelpersDurationFilterTimeSelectorFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentHelpersDurationFilterTimeSelectorFiltersInput>>>;
  fromHours?: InputMaybe<IntFilterInput>;
  fromMinutes?: InputMaybe<IntFilterInput>;
  not?: InputMaybe<ComponentHelpersDurationFilterTimeSelectorFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentHelpersDurationFilterTimeSelectorFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
  toHours?: InputMaybe<IntFilterInput>;
  toMinutes?: InputMaybe<IntFilterInput>;
};

export type ComponentItemsLinkedChallenges = {
  __typename?: 'ComponentItemsLinkedChallenges';
  challenges?: Maybe<ChallengeRelationResponseCollection>;
  id: Scalars['ID']['output'];
};

export type ComponentItemsLinkedChallengesChallengesArgs = {
  filters?: InputMaybe<ChallengeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentItemsLinkedLessons = {
  __typename?: 'ComponentItemsLinkedLessons';
  id: Scalars['ID']['output'];
  lessons?: Maybe<LessonRelationResponseCollection>;
};

export type ComponentItemsLinkedLessonsLessonsArgs = {
  filters?: InputMaybe<LessonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentItemsLinkedProjects = {
  __typename?: 'ComponentItemsLinkedProjects';
  id: Scalars['ID']['output'];
  projects?: Maybe<ProjectRelationResponseCollection>;
};

export type ComponentItemsLinkedProjectsProjectsArgs = {
  filters?: InputMaybe<ProjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentItemsSetting = {
  __typename?: 'ComponentItemsSetting';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ComponentItemsSettingFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentItemsSettingFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentItemsSettingFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentItemsSettingFiltersInput>>>;
  value?: InputMaybe<StringFilterInput>;
};

export type ComponentItemsSettingInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ComponentItemsToCEntry = {
  __typename?: 'ComponentItemsToCEntry';
  entry?: Maybe<ComponentAtomsTextWithTime>;
  id: Scalars['ID']['output'];
  items?: Maybe<Array<Maybe<ComponentAtomsTextWithTime>>>;
};

export type ComponentItemsToCEntryItemsArgs = {
  filters?: InputMaybe<ComponentAtomsTextWithTimeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentItemsTutorialImageStep = {
  __typename?: 'ComponentItemsTutorialImageStep';
  baseFields: ComponentItemsTutorialStepCommonFields;
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  tutorialStepAvailableActions?: Maybe<Array<Maybe<ComponentItemsTutorialStepAvailableActions>>>;
  tutorialStepNeededActions?: Maybe<Array<Maybe<ComponentItemsTutorialStepNeededActions>>>;
};

export type ComponentItemsTutorialImageStepTutorialStepAvailableActionsArgs = {
  filters?: InputMaybe<ComponentItemsTutorialStepAvailableActionsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentItemsTutorialImageStepTutorialStepNeededActionsArgs = {
  filters?: InputMaybe<ComponentItemsTutorialStepNeededActionsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentItemsTutorialStep = {
  __typename?: 'ComponentItemsTutorialStep';
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  overlay: Scalars['Boolean']['output'];
  progressAmount?: Maybe<Scalars['Int']['output']>;
  showProgress: Scalars['Boolean']['output'];
  text?: Maybe<Scalars['String']['output']>;
  timeDelay?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  video?: Maybe<UploadFileRelationResponseCollection>;
};

export type ComponentItemsTutorialStepVideoArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentItemsTutorialStepAvailableActions = {
  __typename?: 'ComponentItemsTutorialStepAvailableActions';
  action?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
};

export type ComponentItemsTutorialStepAvailableActionsFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentItemsTutorialStepAvailableActionsFiltersInput>>>;
  data?: InputMaybe<JsonFilterInput>;
  not?: InputMaybe<ComponentItemsTutorialStepAvailableActionsFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentItemsTutorialStepAvailableActionsFiltersInput>>>;
};

export type ComponentItemsTutorialStepCommonFields = {
  __typename?: 'ComponentItemsTutorialStepCommonFields';
  backButtonAction: Enum_Componentitemstutorialstepcommonfields_Backbuttonaction;
  backButtonText?: Maybe<Scalars['String']['output']>;
  backButtonUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  nextButtonAction: Enum_Componentitemstutorialstepcommonfields_Nextbuttonaction;
  nextButtonText?: Maybe<Scalars['String']['output']>;
  nextButtonUrl?: Maybe<Scalars['String']['output']>;
  showOverlay: Scalars['Boolean']['output'];
  showProgress: Scalars['Boolean']['output'];
  text?: Maybe<Scalars['String']['output']>;
  timeDelay?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type ComponentItemsTutorialStepNeededActions = {
  __typename?: 'ComponentItemsTutorialStepNeededActions';
  action?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
};

export type ComponentItemsTutorialStepNeededActionsFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentItemsTutorialStepNeededActionsFiltersInput>>>;
  data?: InputMaybe<JsonFilterInput>;
  not?: InputMaybe<ComponentItemsTutorialStepNeededActionsFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentItemsTutorialStepNeededActionsFiltersInput>>>;
};

export type ComponentItemsTutorialTextStep = {
  __typename?: 'ComponentItemsTutorialTextStep';
  baseFields: ComponentItemsTutorialStepCommonFields;
  id: Scalars['ID']['output'];
  tutorialStepAvailableActions?: Maybe<Array<Maybe<ComponentItemsTutorialStepAvailableActions>>>;
  tutorialStepNeededActions?: Maybe<Array<Maybe<ComponentItemsTutorialStepNeededActions>>>;
};

export type ComponentItemsTutorialTextStepTutorialStepAvailableActionsArgs = {
  filters?: InputMaybe<ComponentItemsTutorialStepAvailableActionsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentItemsTutorialTextStepTutorialStepNeededActionsArgs = {
  filters?: InputMaybe<ComponentItemsTutorialStepNeededActionsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentItemsTutorialVideoStep = {
  __typename?: 'ComponentItemsTutorialVideoStep';
  baseFields: ComponentItemsTutorialStepCommonFields;
  endImage?: Maybe<UploadFileEntityResponse>;
  endText?: Maybe<Scalars['String']['output']>;
  endTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  startImage?: Maybe<UploadFileEntityResponse>;
  tutorialStepAvailableActions?: Maybe<Array<Maybe<ComponentItemsTutorialStepAvailableActions>>>;
  tutorialStepNeededActions?: Maybe<Array<Maybe<ComponentItemsTutorialStepNeededActions>>>;
  videos: UploadFileRelationResponseCollection;
};

export type ComponentItemsTutorialVideoStepTutorialStepAvailableActionsArgs = {
  filters?: InputMaybe<ComponentItemsTutorialStepAvailableActionsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentItemsTutorialVideoStepTutorialStepNeededActionsArgs = {
  filters?: InputMaybe<ComponentItemsTutorialStepNeededActionsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentItemsTutorialVideoStepVideosArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSectionsCommonSection = {
  __typename?: 'ComponentSectionsCommonSection';
  assets?: Maybe<ComponentSharedAssets>;
  buttons?: Maybe<Array<Maybe<ComponentAtomsLinkableList>>>;
  captions?: Maybe<Array<Maybe<ComponentAtomsLinkableList>>>;
  content?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enumerable?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  learningDuration?: Maybe<ComponentAtomsTimeSelector>;
  preparationDuration?: Maybe<ComponentAtomsTimeSelector>;
  title: Scalars['String']['output'];
};

export type ComponentSectionsCommonSectionButtonsArgs = {
  filters?: InputMaybe<ComponentAtomsLinkableListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSectionsCommonSectionCaptionsArgs = {
  filters?: InputMaybe<ComponentAtomsLinkableListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSectionsLessonPlanSection = {
  __typename?: 'ComponentSectionsLessonPlanSection';
  assets?: Maybe<ComponentSharedAssets>;
  buttons?: Maybe<Array<Maybe<ComponentAtomsLinkableList>>>;
  captions?: Maybe<Array<Maybe<ComponentAtomsLinkableList>>>;
  content?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enumerable?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  learningDuration?: Maybe<ComponentAtomsTimeSelector>;
  preparationDuration?: Maybe<ComponentAtomsTimeSelector>;
  title: Scalars['String']['output'];
};

export type ComponentSectionsLessonPlanSectionButtonsArgs = {
  filters?: InputMaybe<ComponentAtomsLinkableListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSectionsLessonPlanSectionCaptionsArgs = {
  filters?: InputMaybe<ComponentAtomsLinkableListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSectionsLessonRunSection = {
  __typename?: 'ComponentSectionsLessonRunSection';
  assets?: Maybe<ComponentSharedAssets>;
  buttons?: Maybe<Array<Maybe<ComponentAtomsLinkableList>>>;
  captions?: Maybe<Array<Maybe<ComponentAtomsLinkableList>>>;
  content?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enumerable?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  learningDuration?: Maybe<ComponentAtomsTimeSelector>;
  preparationDuration?: Maybe<ComponentAtomsTimeSelector>;
  title: Scalars['String']['output'];
};

export type ComponentSectionsLessonRunSectionButtonsArgs = {
  filters?: InputMaybe<ComponentAtomsLinkableListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSectionsLessonRunSectionCaptionsArgs = {
  filters?: InputMaybe<ComponentAtomsLinkableListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSectionsProjectDeliverySection = {
  __typename?: 'ComponentSectionsProjectDeliverySection';
  assets?: Maybe<ComponentSharedAssets>;
  buttons?: Maybe<Array<Maybe<ComponentAtomsLinkableList>>>;
  captions?: Maybe<Array<Maybe<ComponentAtomsLinkableList>>>;
  content?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enumerable?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  learningDuration?: Maybe<ComponentAtomsTimeSelector>;
  preparationDuration?: Maybe<ComponentAtomsTimeSelector>;
  title: Scalars['String']['output'];
};

export type ComponentSectionsProjectDeliverySectionButtonsArgs = {
  filters?: InputMaybe<ComponentAtomsLinkableListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSectionsProjectDeliverySectionCaptionsArgs = {
  filters?: InputMaybe<ComponentAtomsLinkableListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSectionsProjectEvaluationSection = {
  __typename?: 'ComponentSectionsProjectEvaluationSection';
  assets?: Maybe<ComponentSharedAssets>;
  buttons?: Maybe<Array<Maybe<ComponentAtomsLinkableList>>>;
  captions?: Maybe<Array<Maybe<ComponentAtomsLinkableList>>>;
  content?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enumerable?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  learningDuration?: Maybe<ComponentAtomsTimeSelector>;
  preparationDuration?: Maybe<ComponentAtomsTimeSelector>;
  title: Scalars['String']['output'];
};

export type ComponentSectionsProjectEvaluationSectionButtonsArgs = {
  filters?: InputMaybe<ComponentAtomsLinkableListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSectionsProjectEvaluationSectionCaptionsArgs = {
  filters?: InputMaybe<ComponentAtomsLinkableListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSectionsProjectPlanSection = {
  __typename?: 'ComponentSectionsProjectPlanSection';
  assets?: Maybe<ComponentSharedAssets>;
  buttons?: Maybe<Array<Maybe<ComponentAtomsLinkableList>>>;
  captions?: Maybe<Array<Maybe<ComponentAtomsLinkableList>>>;
  content?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enumerable?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  learningDuration?: Maybe<ComponentAtomsTimeSelector>;
  preparationDuration?: Maybe<ComponentAtomsTimeSelector>;
  title: Scalars['String']['output'];
};

export type ComponentSectionsProjectPlanSectionButtonsArgs = {
  filters?: InputMaybe<ComponentAtomsLinkableListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSectionsProjectPlanSectionCaptionsArgs = {
  filters?: InputMaybe<ComponentAtomsLinkableListFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedAssets = {
  __typename?: 'ComponentSharedAssets';
  files?: Maybe<UploadFileRelationResponseCollection>;
  id: Scalars['ID']['output'];
};

export type ComponentSharedAssetsFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedGallery = {
  __typename?: 'ComponentSharedGallery';
  files?: Maybe<UploadFileRelationResponseCollection>;
  id: Scalars['ID']['output'];
  main: UploadFileEntityResponse;
};

export type ComponentSharedGalleryFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ComponentSharedGalleryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentSharedGalleryFiltersInput>>>;
  not?: InputMaybe<ComponentSharedGalleryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentSharedGalleryFiltersInput>>>;
};

export type ComponentSharedGalleryInput = {
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  main?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentSharedMedia = {
  __typename?: 'ComponentSharedMedia';
  file?: Maybe<UploadFileEntityResponse>;
  id: Scalars['ID']['output'];
};

export type ComponentSharedQuote = {
  __typename?: 'ComponentSharedQuote';
  body?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ComponentSharedRichText = {
  __typename?: 'ComponentSharedRichText';
  body?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type ComponentSharedSeo = {
  __typename?: 'ComponentSharedSeo';
  id: Scalars['ID']['output'];
  metaDescription: Scalars['String']['output'];
  metaTitle: Scalars['String']['output'];
  shareImage?: Maybe<UploadFileEntityResponse>;
};

export type ComponentSharedSeoInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaTitle?: InputMaybe<Scalars['String']['input']>;
  shareImage?: InputMaybe<Scalars['ID']['input']>;
};

export type ComponentSharedSlider = {
  __typename?: 'ComponentSharedSlider';
  files?: Maybe<UploadFileRelationResponseCollection>;
  id: Scalars['ID']['output'];
};

export type ComponentSharedSliderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Dashboard = {
  __typename?: 'Dashboard';
  collections: ComponentDashboardCollections;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  greeting: ComponentDashboardGreetingSection;
  inspiration?: Maybe<ComponentDashboardInspirationSection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<DashboardRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DashboardEntity = {
  __typename?: 'DashboardEntity';
  attributes?: Maybe<Dashboard>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type DashboardEntityResponse = {
  __typename?: 'DashboardEntityResponse';
  data?: Maybe<DashboardEntity>;
};

export type DashboardInput = {
  collections?: InputMaybe<ComponentDashboardCollectionsInput>;
  greeting?: InputMaybe<ComponentDashboardGreetingSectionInput>;
  inspiration?: InputMaybe<ComponentDashboardInspirationSectionInput>;
};

export type DashboardRelationResponseCollection = {
  __typename?: 'DashboardRelationResponseCollection';
  data: Array<DashboardEntity>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  contains?: InputMaybe<Scalars['DateTime']['input']>;
  containsi?: InputMaybe<Scalars['DateTime']['input']>;
  endsWith?: InputMaybe<Scalars['DateTime']['input']>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  eqi?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  ne?: InputMaybe<Scalars['DateTime']['input']>;
  nei?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']['input']>;
  notContainsi?: InputMaybe<Scalars['DateTime']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']['input']>;
};

export type District = {
  __typename?: 'District';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  subscriptions?: Maybe<Array<Maybe<ComponentBusinessRelatedSubscription>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DistrictSubscriptionsArgs = {
  filters?: InputMaybe<ComponentBusinessRelatedSubscriptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type DistrictEntity = {
  __typename?: 'DistrictEntity';
  attributes?: Maybe<District>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type DistrictEntityResponse = {
  __typename?: 'DistrictEntityResponse';
  data?: Maybe<DistrictEntity>;
};

export type DistrictEntityResponseCollection = {
  __typename?: 'DistrictEntityResponseCollection';
  data: Array<DistrictEntity>;
  meta: ResponseCollectionMeta;
};

export type DistrictFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<DistrictFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  externalId?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<DistrictFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<DistrictFiltersInput>>>;
  state?: InputMaybe<StringFilterInput>;
  subscriptions?: InputMaybe<ComponentBusinessRelatedSubscriptionFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type DistrictInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  subscriptions?: InputMaybe<Array<InputMaybe<ComponentBusinessRelatedSubscriptionInput>>>;
};

export enum Enum_Componentbusinessschoolrole_Role {
  Admin = 'admin',
  Employee = 'employee',
  Student = 'student',
}

export enum Enum_Componentcontentlevel_Body {
  Advanced = 'advanced',
  Beginner = 'beginner',
  Intermediate = 'intermediate',
}

export enum Enum_Componentitemstutorialstepcommonfields_Backbuttonaction {
  GoBack = 'go_back',
  GoToTutorials = 'go_to_tutorials',
  GoToUrl = 'go_to_url',
  Hide = 'hide',
  OpenUrlInNewPage = 'open_url_in_new_page',
  StartOver = 'start_over',
}

export enum Enum_Componentitemstutorialstepcommonfields_Nextbuttonaction {
  GoNext = 'go_next',
  GoToTutorials = 'go_to_tutorials',
  GoToUrl = 'go_to_url',
  GoToUrlNewPage = 'go_to_url_new_page',
  Hide = 'hide',
  StartOver = 'start_over',
}

export enum Enum_Editorproject_Type {
  Code = 'code',
  Live = 'live',
}

export enum Enum_Page_Workspace {
  Community = 'community',
  Competitions = 'competitions',
  Dashboard = 'dashboard',
  Help = 'help',
  Library = 'library',
  Pd = 'pd',
  Profile = 'profile',
}

export enum Enum_Tag_Context {
  Activities = 'activities',
  All = 'all',
  Challenges = 'challenges',
  Lessons = 'lessons',
  Projects = 'projects',
  Resources = 'resources',
}

export enum Enum_Tutorial_Editortype {
  Code = 'code',
  Live = 'live',
}

export type EditorProject = {
  __typename?: 'EditorProject';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  editorState: Scalars['JSON']['output'];
  previewImage?: Maybe<UploadFileEntityResponse>;
  title: Scalars['String']['output'];
  type: Enum_Editorproject_Type;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
  uuid: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type EditorProjectEntity = {
  __typename?: 'EditorProjectEntity';
  attributes?: Maybe<EditorProject>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type EditorProjectEntityResponse = {
  __typename?: 'EditorProjectEntityResponse';
  data?: Maybe<EditorProjectEntity>;
};

export type EditorProjectEntityResponseCollection = {
  __typename?: 'EditorProjectEntityResponseCollection';
  data: Array<EditorProjectEntity>;
  meta: ResponseCollectionMeta;
};

export type EditorProjectFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<EditorProjectFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  editorState?: InputMaybe<JsonFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<EditorProjectFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<EditorProjectFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
  uuid?: InputMaybe<StringFilterInput>;
  version?: InputMaybe<StringFilterInput>;
};

export type EditorProjectInput = {
  editorState?: InputMaybe<Scalars['JSON']['input']>;
  previewImage?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Enum_Editorproject_Type>;
  user?: InputMaybe<Scalars['ID']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type EmailDesignerEmailTemplate = {
  __typename?: 'EmailDesignerEmailTemplate';
  bodyHtml?: Maybe<Scalars['String']['output']>;
  bodyText?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  design?: Maybe<Scalars['JSON']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Scalars['JSON']['output']>;
  templateReferenceId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EmailDesignerEmailTemplateEntity = {
  __typename?: 'EmailDesignerEmailTemplateEntity';
  attributes?: Maybe<EmailDesignerEmailTemplate>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type EmailDesignerEmailTemplateEntityResponse = {
  __typename?: 'EmailDesignerEmailTemplateEntityResponse';
  data?: Maybe<EmailDesignerEmailTemplateEntity>;
};

export type EmailDesignerEmailTemplateEntityResponseCollection = {
  __typename?: 'EmailDesignerEmailTemplateEntityResponseCollection';
  data: Array<EmailDesignerEmailTemplateEntity>;
  meta: ResponseCollectionMeta;
};

export type EmailDesignerEmailTemplateFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<EmailDesignerEmailTemplateFiltersInput>>>;
  bodyHtml?: InputMaybe<StringFilterInput>;
  bodyText?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  design?: InputMaybe<JsonFilterInput>;
  enabled?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<EmailDesignerEmailTemplateFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<EmailDesignerEmailTemplateFiltersInput>>>;
  subject?: InputMaybe<StringFilterInput>;
  tags?: InputMaybe<JsonFilterInput>;
  templateReferenceId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type EmailDesignerEmailTemplateInput = {
  bodyHtml?: InputMaybe<Scalars['String']['input']>;
  bodyText?: InputMaybe<Scalars['String']['input']>;
  design?: InputMaybe<Scalars['JSON']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Scalars['JSON']['input']>;
  templateReferenceId?: InputMaybe<Scalars['Int']['input']>;
};

export type Error = {
  __typename?: 'Error';
  code: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

export type Feature = {
  __typename?: 'Feature';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FeatureEntity = {
  __typename?: 'FeatureEntity';
  attributes?: Maybe<Feature>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type FeatureEntityResponse = {
  __typename?: 'FeatureEntityResponse';
  data?: Maybe<FeatureEntity>;
};

export type FeatureEntityResponseCollection = {
  __typename?: 'FeatureEntityResponseCollection';
  data: Array<FeatureEntity>;
  meta: ResponseCollectionMeta;
};

export type FeatureFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<FeatureFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  enabled?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<FeatureFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<FeatureFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type FeatureInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  contains?: InputMaybe<Scalars['Float']['input']>;
  containsi?: InputMaybe<Scalars['Float']['input']>;
  endsWith?: InputMaybe<Scalars['Float']['input']>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  eqi?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  nei?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']['input']>;
  notContainsi?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  startsWith?: InputMaybe<Scalars['Float']['input']>;
};

export type GenericMorph =
  | A1
  | About
  | Activity
  | AppSettings
  | Challenge
  | Complexity
  | ComponentAtomsAction
  | ComponentAtomsItem
  | ComponentAtomsLinkableList
  | ComponentAtomsList
  | ComponentAtomsTextWithTime
  | ComponentAtomsTimeSelector
  | ComponentBusinessRelatedSubscription
  | ComponentBusinessSchoolRole
  | ComponentContentLearningGoals
  | ComponentContentLevel
  | ComponentContentListByTag
  | ComponentContentRequireMaterials
  | ComponentDashboardCollections
  | ComponentDashboardGreetingSection
  | ComponentDashboardInspirationSection
  | ComponentFiltersComplexityFilter
  | ComponentFiltersContentTypeFilter
  | ComponentFiltersDurationFilter
  | ComponentFiltersFavoriteFilter
  | ComponentFiltersGradesFilter
  | ComponentFiltersOwnerFilter
  | ComponentFiltersSharedFilter
  | ComponentFiltersSubjectsFilter
  | ComponentFiltersSubscriptionsFilter
  | ComponentFiltersTagsFilter
  | ComponentHelpersDurationFilterTimeSelector
  | ComponentItemsLinkedChallenges
  | ComponentItemsLinkedLessons
  | ComponentItemsLinkedProjects
  | ComponentItemsSetting
  | ComponentItemsToCEntry
  | ComponentItemsTutorialImageStep
  | ComponentItemsTutorialStep
  | ComponentItemsTutorialStepAvailableActions
  | ComponentItemsTutorialStepCommonFields
  | ComponentItemsTutorialStepNeededActions
  | ComponentItemsTutorialTextStep
  | ComponentItemsTutorialVideoStep
  | ComponentSectionsCommonSection
  | ComponentSectionsLessonPlanSection
  | ComponentSectionsLessonRunSection
  | ComponentSectionsProjectDeliverySection
  | ComponentSectionsProjectEvaluationSection
  | ComponentSectionsProjectPlanSection
  | ComponentSharedAssets
  | ComponentSharedGallery
  | ComponentSharedMedia
  | ComponentSharedQuote
  | ComponentSharedRichText
  | ComponentSharedSeo
  | ComponentSharedSlider
  | Dashboard
  | District
  | EditorProject
  | EmailDesignerEmailTemplate
  | Feature
  | Global
  | Grade
  | I18NLocale
  | Lesson
  | Page
  | Project
  | Resource
  | Room
  | School
  | Scope
  | Section
  | Subject
  | Subscription
  | Tag
  | Tutorial
  | TutorialCategory
  | UploadFile
  | UploadFolder
  | UserTag
  | UsersPermissionsPermission
  | UsersPermissionsRole
  | UsersPermissionsUser;

export type Global = {
  __typename?: 'Global';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  defaultSeo?: Maybe<ComponentSharedSeo>;
  favicon?: Maybe<UploadFileEntityResponse>;
  siteDescription: Scalars['String']['output'];
  siteName: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GlobalEntity = {
  __typename?: 'GlobalEntity';
  attributes?: Maybe<Global>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type GlobalEntityResponse = {
  __typename?: 'GlobalEntityResponse';
  data?: Maybe<GlobalEntity>;
};

export type GlobalInput = {
  defaultSeo?: InputMaybe<ComponentSharedSeoInput>;
  favicon?: InputMaybe<Scalars['ID']['input']>;
  siteDescription?: InputMaybe<Scalars['String']['input']>;
  siteName?: InputMaybe<Scalars['String']['input']>;
};

export type Grade = {
  __typename?: 'Grade';
  activities?: Maybe<ActivityRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lessons?: Maybe<LessonRelationResponseCollection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<GradeRelationResponseCollection>;
  name: Scalars['String']['output'];
  ordering?: Maybe<Scalars['Int']['output']>;
  projects?: Maybe<ProjectRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GradeActivitiesArgs = {
  filters?: InputMaybe<ActivityFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GradeLessonsArgs = {
  filters?: InputMaybe<LessonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GradeLocalizationsArgs = {
  filters?: InputMaybe<GradeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GradeProjectsArgs = {
  filters?: InputMaybe<ProjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type GradeEntity = {
  __typename?: 'GradeEntity';
  attributes?: Maybe<Grade>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type GradeEntityResponse = {
  __typename?: 'GradeEntityResponse';
  data?: Maybe<GradeEntity>;
};

export type GradeEntityResponseCollection = {
  __typename?: 'GradeEntityResponseCollection';
  data: Array<GradeEntity>;
  meta: ResponseCollectionMeta;
};

export type GradeFiltersInput = {
  activities?: InputMaybe<ActivityFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<GradeFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  lessons?: InputMaybe<LessonFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<GradeFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<GradeFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<GradeFiltersInput>>>;
  ordering?: InputMaybe<IntFilterInput>;
  projects?: InputMaybe<ProjectFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type GradeInput = {
  activities?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lessons?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  ordering?: InputMaybe<Scalars['Int']['input']>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type GradeRelationResponseCollection = {
  __typename?: 'GradeRelationResponseCollection';
  data: Array<GradeEntity>;
};

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  containsi?: InputMaybe<Scalars['ID']['input']>;
  endsWith?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  eqi?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  nei?: InputMaybe<Scalars['ID']['input']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  notContainsi?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startsWith?: InputMaybe<Scalars['ID']['input']>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  contains?: InputMaybe<Scalars['Int']['input']>;
  containsi?: InputMaybe<Scalars['Int']['input']>;
  endsWith?: InputMaybe<Scalars['Int']['input']>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  eqi?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  nei?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']['input']>;
  notContainsi?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  startsWith?: InputMaybe<Scalars['Int']['input']>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  contains?: InputMaybe<Scalars['JSON']['input']>;
  containsi?: InputMaybe<Scalars['JSON']['input']>;
  endsWith?: InputMaybe<Scalars['JSON']['input']>;
  eq?: InputMaybe<Scalars['JSON']['input']>;
  eqi?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  ne?: InputMaybe<Scalars['JSON']['input']>;
  nei?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']['input']>;
  notContainsi?: InputMaybe<Scalars['JSON']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  startsWith?: InputMaybe<Scalars['JSON']['input']>;
};

export type Lesson = {
  __typename?: 'Lesson';
  complexity?: Maybe<ComplexityEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  gallery?: Maybe<ComponentSharedGallery>;
  goals?: Maybe<Array<Maybe<ComponentContentLearningGoals>>>;
  grades?: Maybe<GradeRelationResponseCollection>;
  learningDuration?: Maybe<ComponentAtomsTimeSelector>;
  level?: Maybe<ComponentContentLevel>;
  linkedItems?: Maybe<Array<Maybe<LessonLinkedItemsDynamicZone>>>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<LessonRelationResponseCollection>;
  materials?: Maybe<Array<Maybe<ComponentContentRequireMaterials>>>;
  name: Scalars['String']['output'];
  ordering?: Maybe<Scalars['Int']['output']>;
  preparationDuration?: Maybe<ComponentAtomsTimeSelector>;
  preview?: Maybe<UploadFileEntityResponse>;
  project?: Maybe<ProjectEntityResponse>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  rooms?: Maybe<RoomRelationResponseCollection>;
  sections?: Maybe<Array<Maybe<LessonSectionsDynamicZone>>>;
  slug: Scalars['String']['output'];
  subjects?: Maybe<SubjectRelationResponseCollection>;
  subscriptions?: Maybe<SubscriptionRelationResponseCollection>;
  tags?: Maybe<TagRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LessonGoalsArgs = {
  filters?: InputMaybe<ComponentContentLearningGoalsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LessonGradesArgs = {
  filters?: InputMaybe<GradeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LessonLocalizationsArgs = {
  filters?: InputMaybe<LessonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LessonMaterialsArgs = {
  filters?: InputMaybe<ComponentContentRequireMaterialsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LessonRoomsArgs = {
  filters?: InputMaybe<RoomFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LessonSubjectsArgs = {
  filters?: InputMaybe<SubjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LessonSubscriptionsArgs = {
  filters?: InputMaybe<SubscriptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LessonTagsArgs = {
  filters?: InputMaybe<TagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LessonEntity = {
  __typename?: 'LessonEntity';
  attributes?: Maybe<Lesson>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type LessonEntityResponse = {
  __typename?: 'LessonEntityResponse';
  data?: Maybe<LessonEntity>;
};

export type LessonEntityResponseCollection = {
  __typename?: 'LessonEntityResponseCollection';
  data: Array<LessonEntity>;
  meta: ResponseCollectionMeta;
};

export type LessonFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<LessonFiltersInput>>>;
  complexity?: InputMaybe<ComplexityFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  gallery?: InputMaybe<ComponentSharedGalleryFiltersInput>;
  goals?: InputMaybe<ComponentContentLearningGoalsFiltersInput>;
  grades?: InputMaybe<GradeFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  learningDuration?: InputMaybe<ComponentAtomsTimeSelectorFiltersInput>;
  level?: InputMaybe<ComponentContentLevelFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<LessonFiltersInput>;
  materials?: InputMaybe<ComponentContentRequireMaterialsFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<LessonFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LessonFiltersInput>>>;
  ordering?: InputMaybe<IntFilterInput>;
  preparationDuration?: InputMaybe<ComponentAtomsTimeSelectorFiltersInput>;
  project?: InputMaybe<ProjectFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  rooms?: InputMaybe<RoomFiltersInput>;
  slug?: InputMaybe<StringFilterInput>;
  subjects?: InputMaybe<SubjectFiltersInput>;
  subscriptions?: InputMaybe<SubscriptionFiltersInput>;
  tags?: InputMaybe<TagFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type LessonInput = {
  complexity?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  gallery?: InputMaybe<ComponentSharedGalleryInput>;
  goals?: InputMaybe<Array<InputMaybe<ComponentContentLearningGoalsInput>>>;
  grades?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  learningDuration?: InputMaybe<ComponentAtomsTimeSelectorInput>;
  level?: InputMaybe<ComponentContentLevelInput>;
  linkedItems?: InputMaybe<Array<Scalars['LessonLinkedItemsDynamicZoneInput']['input']>>;
  materials?: InputMaybe<Array<InputMaybe<ComponentContentRequireMaterialsInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  ordering?: InputMaybe<Scalars['Int']['input']>;
  preparationDuration?: InputMaybe<ComponentAtomsTimeSelectorInput>;
  preview?: InputMaybe<Scalars['ID']['input']>;
  project?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  rooms?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  sections?: InputMaybe<Array<Scalars['LessonSectionsDynamicZoneInput']['input']>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  subscriptions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type LessonLinkedItemsDynamicZone =
  | ComponentItemsLinkedChallenges
  | ComponentItemsLinkedLessons
  | ComponentItemsLinkedProjects
  | Error;

export type LessonRelationResponseCollection = {
  __typename?: 'LessonRelationResponseCollection';
  data: Array<LessonEntity>;
};

export type LessonSectionsDynamicZone =
  | ComponentSectionsCommonSection
  | ComponentSectionsLessonPlanSection
  | ComponentSectionsLessonRunSection
  | Error;

export type Mutation = {
  __typename?: 'Mutation';
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createActivity?: Maybe<ActivityEntityResponse>;
  createActivityLocalization?: Maybe<ActivityEntityResponse>;
  createAppSettings?: Maybe<AppSettingsEntityResponse>;
  createChallenge?: Maybe<ChallengeEntityResponse>;
  createChallengeLocalization?: Maybe<ChallengeEntityResponse>;
  createComplexity?: Maybe<ComplexityEntityResponse>;
  createComplexityLocalization?: Maybe<ComplexityEntityResponse>;
  createDashboardLocalization?: Maybe<DashboardEntityResponse>;
  createDistrict?: Maybe<DistrictEntityResponse>;
  createEditorProject?: Maybe<EditorProjectEntityResponse>;
  createEmailDesignerEmailTemplate?: Maybe<EmailDesignerEmailTemplateEntityResponse>;
  createFeature?: Maybe<FeatureEntityResponse>;
  createGrade?: Maybe<GradeEntityResponse>;
  createGradeLocalization?: Maybe<GradeEntityResponse>;
  createLesson?: Maybe<LessonEntityResponse>;
  createLessonLocalization?: Maybe<LessonEntityResponse>;
  createPage?: Maybe<PageEntityResponse>;
  createPageLocalization?: Maybe<PageEntityResponse>;
  createProject?: Maybe<ProjectEntityResponse>;
  createProjectLocalization?: Maybe<ProjectEntityResponse>;
  createResource?: Maybe<ResourceEntityResponse>;
  createResourceLocalization?: Maybe<ResourceEntityResponse>;
  createRoom?: Maybe<RoomEntityResponse>;
  createRoomLocalization?: Maybe<RoomEntityResponse>;
  createSchool?: Maybe<SchoolEntityResponse>;
  createScope?: Maybe<ScopeEntityResponse>;
  createSection?: Maybe<SectionEntityResponse>;
  createSubject?: Maybe<SubjectEntityResponse>;
  createSubjectLocalization?: Maybe<SubjectEntityResponse>;
  createSubscription?: Maybe<SubscriptionEntityResponse>;
  createTag?: Maybe<TagEntityResponse>;
  createTagLocalization?: Maybe<TagEntityResponse>;
  createTutorial?: Maybe<TutorialEntityResponse>;
  createTutorialCategory?: Maybe<TutorialCategoryEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  createUserTag?: Maybe<UserTagEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteA1?: Maybe<A1EntityResponse>;
  deleteAbout?: Maybe<AboutEntityResponse>;
  deleteActivity?: Maybe<ActivityEntityResponse>;
  deleteAppSettings?: Maybe<AppSettingsEntityResponse>;
  deleteChallenge?: Maybe<ChallengeEntityResponse>;
  deleteComplexity?: Maybe<ComplexityEntityResponse>;
  deleteDashboard?: Maybe<DashboardEntityResponse>;
  deleteDistrict?: Maybe<DistrictEntityResponse>;
  deleteEditorProject?: Maybe<EditorProjectEntityResponse>;
  deleteEmailDesignerEmailTemplate?: Maybe<EmailDesignerEmailTemplateEntityResponse>;
  deleteFeature?: Maybe<FeatureEntityResponse>;
  deleteGlobal?: Maybe<GlobalEntityResponse>;
  deleteGrade?: Maybe<GradeEntityResponse>;
  deleteLesson?: Maybe<LessonEntityResponse>;
  deletePage?: Maybe<PageEntityResponse>;
  deleteProject?: Maybe<ProjectEntityResponse>;
  deleteResource?: Maybe<ResourceEntityResponse>;
  deleteRoom?: Maybe<RoomEntityResponse>;
  deleteSchool?: Maybe<SchoolEntityResponse>;
  deleteScope?: Maybe<ScopeEntityResponse>;
  deleteSection?: Maybe<SectionEntityResponse>;
  deleteSubject?: Maybe<SubjectEntityResponse>;
  deleteSubscription?: Maybe<SubscriptionEntityResponse>;
  deleteTag?: Maybe<TagEntityResponse>;
  deleteTutorial?: Maybe<TutorialEntityResponse>;
  deleteTutorialCategory?: Maybe<TutorialCategoryEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  deleteUserTag?: Maybe<UserTagEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  testLogin: UsersPermissionsLoginPayload;
  updateA1?: Maybe<A1EntityResponse>;
  updateAbout?: Maybe<AboutEntityResponse>;
  updateActivity?: Maybe<ActivityEntityResponse>;
  updateAppSettings?: Maybe<AppSettingsEntityResponse>;
  updateChallenge?: Maybe<ChallengeEntityResponse>;
  updateComplexity?: Maybe<ComplexityEntityResponse>;
  updateDashboard?: Maybe<DashboardEntityResponse>;
  updateDistrict?: Maybe<DistrictEntityResponse>;
  updateEditorProject?: Maybe<EditorProjectEntityResponse>;
  updateEmailDesignerEmailTemplate?: Maybe<EmailDesignerEmailTemplateEntityResponse>;
  updateFeature?: Maybe<FeatureEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateGlobal?: Maybe<GlobalEntityResponse>;
  updateGrade?: Maybe<GradeEntityResponse>;
  updateLesson?: Maybe<LessonEntityResponse>;
  updatePage?: Maybe<PageEntityResponse>;
  updateProject?: Maybe<ProjectEntityResponse>;
  updateResource?: Maybe<ResourceEntityResponse>;
  updateRoom?: Maybe<RoomEntityResponse>;
  updateSchool?: Maybe<SchoolEntityResponse>;
  updateScope?: Maybe<ScopeEntityResponse>;
  updateSection?: Maybe<SectionEntityResponse>;
  updateSubject?: Maybe<SubjectEntityResponse>;
  updateSubscription?: Maybe<SubscriptionEntityResponse>;
  updateTag?: Maybe<TagEntityResponse>;
  updateTutorial?: Maybe<TutorialEntityResponse>;
  updateTutorialCategory?: Maybe<TutorialCategoryEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  updateUserTag?: Maybe<UserTagEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  upload: UploadFileEntityResponse;
};

export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationCreateActivityArgs = {
  data: ActivityInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateActivityLocalizationArgs = {
  data?: InputMaybe<ActivityInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateAppSettingsArgs = {
  data: AppSettingsInput;
};

export type MutationCreateChallengeArgs = {
  data: ChallengeInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateChallengeLocalizationArgs = {
  data?: InputMaybe<ChallengeInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateComplexityArgs = {
  data: ComplexityInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateComplexityLocalizationArgs = {
  data?: InputMaybe<ComplexityInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateDashboardLocalizationArgs = {
  data?: InputMaybe<DashboardInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateDistrictArgs = {
  data: DistrictInput;
};

export type MutationCreateEditorProjectArgs = {
  data: EditorProjectInput;
};

export type MutationCreateEmailDesignerEmailTemplateArgs = {
  data: EmailDesignerEmailTemplateInput;
};

export type MutationCreateFeatureArgs = {
  data: FeatureInput;
};

export type MutationCreateGradeArgs = {
  data: GradeInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateGradeLocalizationArgs = {
  data?: InputMaybe<GradeInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateLessonArgs = {
  data: LessonInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateLessonLocalizationArgs = {
  data?: InputMaybe<LessonInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreatePageArgs = {
  data: PageInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreatePageLocalizationArgs = {
  data?: InputMaybe<PageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateProjectArgs = {
  data: ProjectInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateProjectLocalizationArgs = {
  data?: InputMaybe<ProjectInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateResourceArgs = {
  data: ResourceInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateResourceLocalizationArgs = {
  data?: InputMaybe<ResourceInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateRoomArgs = {
  data: RoomInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateRoomLocalizationArgs = {
  data?: InputMaybe<RoomInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateSchoolArgs = {
  data: SchoolInput;
};

export type MutationCreateScopeArgs = {
  data: ScopeInput;
};

export type MutationCreateSectionArgs = {
  data: SectionInput;
};

export type MutationCreateSubjectArgs = {
  data: SubjectInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateSubjectLocalizationArgs = {
  data?: InputMaybe<SubjectInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateSubscriptionArgs = {
  data: SubscriptionInput;
};

export type MutationCreateTagArgs = {
  data: TagInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateTagLocalizationArgs = {
  data?: InputMaybe<TagInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationCreateTutorialArgs = {
  data: TutorialInput;
};

export type MutationCreateTutorialCategoryArgs = {
  data: TutorialCategoryInput;
};

export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};

export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};

export type MutationCreateUserTagArgs = {
  data: UserTagInput;
};

export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};

export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};

export type MutationDeleteActivityArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteAppSettingsArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteChallengeArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteComplexityArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteDashboardArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteDistrictArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteEditorProjectArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteEmailDesignerEmailTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFeatureArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteGradeArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteLessonArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeletePageArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteProjectArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteResourceArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteRoomArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteSchoolArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteScopeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSectionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteSubjectArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteSubscriptionArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteTagArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationDeleteTutorialArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteTutorialCategoryArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUserTagArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID']['input'];
};

export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String']['input'];
};

export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};

export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  files: Array<InputMaybe<Scalars['Upload']['input']>>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};

export type MutationRemoveFileArgs = {
  id: Scalars['ID']['input'];
};

export type MutationResetPasswordArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationTestLoginArgs = {
  input: TestLoginInput;
};

export type MutationUpdateA1Args = {
  data: A1Input;
};

export type MutationUpdateAboutArgs = {
  data: AboutInput;
};

export type MutationUpdateActivityArgs = {
  data: ActivityInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateAppSettingsArgs = {
  data: AppSettingsInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateChallengeArgs = {
  data: ChallengeInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateComplexityArgs = {
  data: ComplexityInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateDashboardArgs = {
  data: DashboardInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateDistrictArgs = {
  data: DistrictInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateEditorProjectArgs = {
  data: EditorProjectInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateEmailDesignerEmailTemplateArgs = {
  data: EmailDesignerEmailTemplateInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateFeatureArgs = {
  data: FeatureInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID']['input'];
  info?: InputMaybe<FileInfoInput>;
};

export type MutationUpdateGlobalArgs = {
  data: GlobalInput;
};

export type MutationUpdateGradeArgs = {
  data: GradeInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateLessonArgs = {
  data: LessonInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdatePageArgs = {
  data: PageInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateProjectArgs = {
  data: ProjectInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateResourceArgs = {
  data: ResourceInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateRoomArgs = {
  data: RoomInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateSchoolArgs = {
  data: SchoolInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateScopeArgs = {
  data: ScopeInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateSectionArgs = {
  data: SectionInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateSubjectArgs = {
  data: SubjectInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateSubscriptionArgs = {
  data: SubscriptionInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateTagArgs = {
  data: TagInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type MutationUpdateTutorialArgs = {
  data: TutorialInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateTutorialCategoryArgs = {
  data: TutorialCategoryInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUserTagArgs = {
  data: UserTagInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID']['input'];
};

export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID']['input'];
};

export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
};

export type Page = {
  __typename?: 'Page';
  blocks?: Maybe<Array<Maybe<PageBlocksDynamicZone>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<PageRelationResponseCollection>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  workspace?: Maybe<Enum_Page_Workspace>;
};

export type PageLocalizationsArgs = {
  filters?: InputMaybe<PageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PageBlocksDynamicZone =
  | ComponentDashboardCollections
  | ComponentSectionsCommonSection
  | ComponentSharedGallery
  | ComponentSharedMedia
  | ComponentSharedRichText
  | Error;

export type PageEntity = {
  __typename?: 'PageEntity';
  attributes?: Maybe<Page>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type PageEntityResponse = {
  __typename?: 'PageEntityResponse';
  data?: Maybe<PageEntity>;
};

export type PageEntityResponseCollection = {
  __typename?: 'PageEntityResponseCollection';
  data: Array<PageEntity>;
  meta: ResponseCollectionMeta;
};

export type PageFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PageFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<PageFiltersInput>;
  not?: InputMaybe<PageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  workspace?: InputMaybe<StringFilterInput>;
};

export type PageInput = {
  blocks?: InputMaybe<Array<Scalars['PageBlocksDynamicZoneInput']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  workspace?: InputMaybe<Enum_Page_Workspace>;
};

export type PageRelationResponseCollection = {
  __typename?: 'PageRelationResponseCollection';
  data: Array<PageEntity>;
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export type Project = {
  __typename?: 'Project';
  activities?: Maybe<ActivityRelationResponseCollection>;
  complexity?: Maybe<ComplexityEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  gallery?: Maybe<ComponentSharedGallery>;
  goals?: Maybe<Array<Maybe<ComponentContentLearningGoals>>>;
  grades?: Maybe<GradeRelationResponseCollection>;
  learningDuration?: Maybe<ComponentAtomsTimeSelector>;
  lessons?: Maybe<LessonRelationResponseCollection>;
  level?: Maybe<ComponentContentLevel>;
  linkedItems?: Maybe<Array<Maybe<ProjectLinkedItemsDynamicZone>>>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<ProjectRelationResponseCollection>;
  materials?: Maybe<Array<Maybe<ComponentContentRequireMaterials>>>;
  name: Scalars['String']['output'];
  ordering?: Maybe<Scalars['Int']['output']>;
  preparationDuration?: Maybe<ComponentAtomsTimeSelector>;
  preview?: Maybe<UploadFileEntityResponse>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  rooms?: Maybe<RoomRelationResponseCollection>;
  sections?: Maybe<Array<Maybe<ProjectSectionsDynamicZone>>>;
  slug: Scalars['String']['output'];
  subjects?: Maybe<SubjectRelationResponseCollection>;
  subscriptions?: Maybe<SubscriptionRelationResponseCollection>;
  tableOfContents?: Maybe<Array<Maybe<ProjectTableOfContentsDynamicZone>>>;
  tags?: Maybe<TagRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProjectActivitiesArgs = {
  filters?: InputMaybe<ActivityFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProjectGoalsArgs = {
  filters?: InputMaybe<ComponentContentLearningGoalsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProjectGradesArgs = {
  filters?: InputMaybe<GradeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProjectLessonsArgs = {
  filters?: InputMaybe<LessonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProjectLocalizationsArgs = {
  filters?: InputMaybe<ProjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProjectMaterialsArgs = {
  filters?: InputMaybe<ComponentContentRequireMaterialsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProjectRoomsArgs = {
  filters?: InputMaybe<RoomFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProjectSubjectsArgs = {
  filters?: InputMaybe<SubjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProjectSubscriptionsArgs = {
  filters?: InputMaybe<SubscriptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProjectTagsArgs = {
  filters?: InputMaybe<TagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProjectEntity = {
  __typename?: 'ProjectEntity';
  attributes?: Maybe<Project>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ProjectEntityResponse = {
  __typename?: 'ProjectEntityResponse';
  data?: Maybe<ProjectEntity>;
};

export type ProjectEntityResponseCollection = {
  __typename?: 'ProjectEntityResponseCollection';
  data: Array<ProjectEntity>;
  meta: ResponseCollectionMeta;
};

export type ProjectFiltersInput = {
  activities?: InputMaybe<ActivityFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<ProjectFiltersInput>>>;
  complexity?: InputMaybe<ComplexityFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  gallery?: InputMaybe<ComponentSharedGalleryFiltersInput>;
  goals?: InputMaybe<ComponentContentLearningGoalsFiltersInput>;
  grades?: InputMaybe<GradeFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  learningDuration?: InputMaybe<ComponentAtomsTimeSelectorFiltersInput>;
  lessons?: InputMaybe<LessonFiltersInput>;
  level?: InputMaybe<ComponentContentLevelFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<ProjectFiltersInput>;
  materials?: InputMaybe<ComponentContentRequireMaterialsFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ProjectFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ProjectFiltersInput>>>;
  ordering?: InputMaybe<IntFilterInput>;
  preparationDuration?: InputMaybe<ComponentAtomsTimeSelectorFiltersInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  rooms?: InputMaybe<RoomFiltersInput>;
  slug?: InputMaybe<StringFilterInput>;
  subjects?: InputMaybe<SubjectFiltersInput>;
  subscriptions?: InputMaybe<SubscriptionFiltersInput>;
  tags?: InputMaybe<TagFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ProjectInput = {
  activities?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  complexity?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  gallery?: InputMaybe<ComponentSharedGalleryInput>;
  goals?: InputMaybe<Array<InputMaybe<ComponentContentLearningGoalsInput>>>;
  grades?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  learningDuration?: InputMaybe<ComponentAtomsTimeSelectorInput>;
  lessons?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  level?: InputMaybe<ComponentContentLevelInput>;
  linkedItems?: InputMaybe<Array<Scalars['ProjectLinkedItemsDynamicZoneInput']['input']>>;
  materials?: InputMaybe<Array<InputMaybe<ComponentContentRequireMaterialsInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  ordering?: InputMaybe<Scalars['Int']['input']>;
  preparationDuration?: InputMaybe<ComponentAtomsTimeSelectorInput>;
  preview?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  rooms?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  sections?: InputMaybe<Array<Scalars['ProjectSectionsDynamicZoneInput']['input']>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  subscriptions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  tableOfContents?: InputMaybe<Array<Scalars['ProjectTableOfContentsDynamicZoneInput']['input']>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ProjectLinkedItemsDynamicZone =
  | ComponentItemsLinkedChallenges
  | ComponentItemsLinkedLessons
  | ComponentItemsLinkedProjects
  | Error;

export type ProjectRelationResponseCollection = {
  __typename?: 'ProjectRelationResponseCollection';
  data: Array<ProjectEntity>;
};

export type ProjectSectionsDynamicZone =
  | ComponentSectionsCommonSection
  | ComponentSectionsProjectDeliverySection
  | ComponentSectionsProjectEvaluationSection
  | ComponentSectionsProjectPlanSection
  | Error;

export type ProjectTableOfContentsDynamicZone = ComponentItemsToCEntry | Error;

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW',
}

export type Query = {
  __typename?: 'Query';
  a1?: Maybe<A1EntityResponse>;
  about?: Maybe<AboutEntityResponse>;
  activities?: Maybe<ActivityEntityResponseCollection>;
  activity?: Maybe<ActivityEntityResponse>;
  appSettings?: Maybe<AppSettingsEntityResponse>;
  appsSettings?: Maybe<AppSettingsEntityResponseCollection>;
  challenge?: Maybe<ChallengeEntityResponse>;
  challenges?: Maybe<ChallengeEntityResponseCollection>;
  complexities?: Maybe<ComplexityEntityResponseCollection>;
  complexity?: Maybe<ComplexityEntityResponse>;
  dashboard?: Maybe<DashboardEntityResponse>;
  district?: Maybe<DistrictEntityResponse>;
  districts?: Maybe<DistrictEntityResponseCollection>;
  editorProject?: Maybe<EditorProjectEntityResponse>;
  editorProjects?: Maybe<EditorProjectEntityResponseCollection>;
  emailDesignerEmailTemplate?: Maybe<EmailDesignerEmailTemplateEntityResponse>;
  emailDesignerEmailTemplates?: Maybe<EmailDesignerEmailTemplateEntityResponseCollection>;
  feature?: Maybe<FeatureEntityResponse>;
  features?: Maybe<FeatureEntityResponseCollection>;
  global?: Maybe<GlobalEntityResponse>;
  grade?: Maybe<GradeEntityResponse>;
  grades?: Maybe<GradeEntityResponseCollection>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  lesson?: Maybe<LessonEntityResponse>;
  lessons?: Maybe<LessonEntityResponseCollection>;
  me?: Maybe<UsersPermissionsMe>;
  page?: Maybe<PageEntityResponse>;
  pages?: Maybe<PageEntityResponseCollection>;
  project?: Maybe<ProjectEntityResponse>;
  projects?: Maybe<ProjectEntityResponseCollection>;
  resource?: Maybe<ResourceEntityResponse>;
  resources?: Maybe<ResourceEntityResponseCollection>;
  room?: Maybe<RoomEntityResponse>;
  roomEntry?: Maybe<RoomEntryResponse>;
  roomFilters?: Maybe<RoomEntityResponse>;
  rooms?: Maybe<RoomEntityResponseCollection>;
  school?: Maybe<SchoolEntityResponse>;
  schools?: Maybe<SchoolEntityResponseCollection>;
  scope?: Maybe<ScopeEntityResponse>;
  scopes?: Maybe<ScopeEntityResponseCollection>;
  search?: Maybe<SearchResponse>;
  section?: Maybe<SectionEntityResponse>;
  sections?: Maybe<SectionEntityResponseCollection>;
  sectionsDetails?: Maybe<SectionsDetailsResponseCollection>;
  subject?: Maybe<SubjectEntityResponse>;
  subjects?: Maybe<SubjectEntityResponseCollection>;
  subscription?: Maybe<SubscriptionEntityResponse>;
  subscriptions?: Maybe<SubscriptionEntityResponseCollection>;
  tag?: Maybe<TagEntityResponse>;
  tags?: Maybe<TagEntityResponseCollection>;
  tutorial?: Maybe<TutorialEntityResponse>;
  tutorialCategories?: Maybe<TutorialCategoryEntityResponseCollection>;
  tutorialCategory?: Maybe<TutorialCategoryEntityResponse>;
  tutorials?: Maybe<TutorialEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  userEditorProjectsCount?: Maybe<UserEditorProjectsCountResponse>;
  userTag?: Maybe<UserTagEntityResponse>;
  userTags?: Maybe<UserTagEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
};

export type QueryActivitiesArgs = {
  filters?: InputMaybe<ActivityFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryActivityArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QueryAppSettingsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryAppsSettingsArgs = {
  filters?: InputMaybe<AppSettingsFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryChallengeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QueryChallengesArgs = {
  filters?: InputMaybe<ChallengeFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryComplexitiesArgs = {
  filters?: InputMaybe<ComplexityFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryComplexityArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QueryDashboardArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QueryDistrictArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryDistrictsArgs = {
  filters?: InputMaybe<DistrictFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryEditorProjectArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEditorProjectsArgs = {
  filters?: InputMaybe<EditorProjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryEmailDesignerEmailTemplateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEmailDesignerEmailTemplatesArgs = {
  filters?: InputMaybe<EmailDesignerEmailTemplateFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryFeatureArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryFeaturesArgs = {
  filters?: InputMaybe<FeatureFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryGradeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QueryGradesArgs = {
  filters?: InputMaybe<GradeFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryLessonArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QueryLessonsArgs = {
  filters?: InputMaybe<LessonFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryPageArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QueryPagesArgs = {
  filters?: InputMaybe<PageFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryProjectArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QueryProjectsArgs = {
  filters?: InputMaybe<ProjectFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryResourceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QueryResourcesArgs = {
  filters?: InputMaybe<ResourceFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryRoomArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QueryRoomEntryArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  userFilters?: InputMaybe<Scalars['JSON']['input']>;
};

export type QueryRoomFiltersArgs = {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRoomsArgs = {
  filters?: InputMaybe<RoomFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySchoolArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySchoolsArgs = {
  filters?: InputMaybe<SchoolFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryScopeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryScopesArgs = {
  filters?: InputMaybe<ScopeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySearchArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  query: Scalars['String']['input'];
};

export type QuerySectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySectionsArgs = {
  filters?: InputMaybe<SectionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySubjectArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QuerySubjectsArgs = {
  filters?: InputMaybe<SubjectFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QuerySubscriptionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySubscriptionsArgs = {
  filters?: InputMaybe<SubscriptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryTagArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
};

export type QueryTagsArgs = {
  filters?: InputMaybe<TagFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryTutorialArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryTutorialCategoriesArgs = {
  filters?: InputMaybe<TutorialCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryTutorialCategoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryTutorialsArgs = {
  filters?: InputMaybe<TutorialFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUserTagArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserTagsArgs = {
  filters?: InputMaybe<UserTagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Resource = {
  __typename?: 'Resource';
  complexity?: Maybe<ComplexityEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  gallery?: Maybe<ComponentSharedGallery>;
  level?: Maybe<ComponentContentLevel>;
  linkedItems?: Maybe<Array<Maybe<ResourceLinkedItemsDynamicZone>>>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<ResourceRelationResponseCollection>;
  name: Scalars['String']['output'];
  ordering?: Maybe<Scalars['Int']['output']>;
  preview?: Maybe<UploadFileEntityResponse>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  rooms?: Maybe<RoomRelationResponseCollection>;
  sections?: Maybe<Array<Maybe<ResourceSectionsDynamicZone>>>;
  slug: Scalars['String']['output'];
  subjects?: Maybe<SubjectRelationResponseCollection>;
  subscriptions?: Maybe<SubscriptionRelationResponseCollection>;
  tags?: Maybe<TagRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ResourceLocalizationsArgs = {
  filters?: InputMaybe<ResourceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ResourceRoomsArgs = {
  filters?: InputMaybe<RoomFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ResourceSubjectsArgs = {
  filters?: InputMaybe<SubjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ResourceSubscriptionsArgs = {
  filters?: InputMaybe<SubscriptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ResourceTagsArgs = {
  filters?: InputMaybe<TagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ResourceEntity = {
  __typename?: 'ResourceEntity';
  attributes?: Maybe<Resource>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ResourceEntityResponse = {
  __typename?: 'ResourceEntityResponse';
  data?: Maybe<ResourceEntity>;
};

export type ResourceEntityResponseCollection = {
  __typename?: 'ResourceEntityResponseCollection';
  data: Array<ResourceEntity>;
  meta: ResponseCollectionMeta;
};

export type ResourceFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ResourceFiltersInput>>>;
  complexity?: InputMaybe<ComplexityFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  gallery?: InputMaybe<ComponentSharedGalleryFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  level?: InputMaybe<ComponentContentLevelFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<ResourceFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ResourceFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ResourceFiltersInput>>>;
  ordering?: InputMaybe<IntFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  rooms?: InputMaybe<RoomFiltersInput>;
  slug?: InputMaybe<StringFilterInput>;
  subjects?: InputMaybe<SubjectFiltersInput>;
  subscriptions?: InputMaybe<SubscriptionFiltersInput>;
  tags?: InputMaybe<TagFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ResourceInput = {
  complexity?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  gallery?: InputMaybe<ComponentSharedGalleryInput>;
  level?: InputMaybe<ComponentContentLevelInput>;
  linkedItems?: InputMaybe<Array<Scalars['ResourceLinkedItemsDynamicZoneInput']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  ordering?: InputMaybe<Scalars['Int']['input']>;
  preview?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  rooms?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  sections?: InputMaybe<Array<Scalars['ResourceSectionsDynamicZoneInput']['input']>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  subscriptions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ResourceLinkedItemsDynamicZone =
  | ComponentItemsLinkedChallenges
  | ComponentItemsLinkedLessons
  | ComponentItemsLinkedProjects
  | Error;

export type ResourceRelationResponseCollection = {
  __typename?: 'ResourceRelationResponseCollection';
  data: Array<ResourceEntity>;
};

export type ResourceSectionsDynamicZone = ComponentSectionsCommonSection | Error;

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type Room = {
  __typename?: 'Room';
  activities?: Maybe<ActivityRelationResponseCollection>;
  allowedFilters: Array<Maybe<RoomAllowedFiltersDynamicZone>>;
  challenges?: Maybe<ChallengeRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enablePagination?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  isTopLevel?: Maybe<Scalars['Boolean']['output']>;
  lessons?: Maybe<LessonRelationResponseCollection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<RoomRelationResponseCollection>;
  name: Scalars['String']['output'];
  pageSize?: Maybe<Scalars['Int']['output']>;
  parentRoom?: Maybe<RoomEntityResponse>;
  projects?: Maybe<ProjectRelationResponseCollection>;
  resources?: Maybe<ResourceRelationResponseCollection>;
  showHeading?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  subscriptions?: Maybe<SubscriptionRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RoomActivitiesArgs = {
  filters?: InputMaybe<ActivityFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RoomChallengesArgs = {
  filters?: InputMaybe<ChallengeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RoomLessonsArgs = {
  filters?: InputMaybe<LessonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RoomLocalizationsArgs = {
  filters?: InputMaybe<RoomFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RoomProjectsArgs = {
  filters?: InputMaybe<ProjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RoomResourcesArgs = {
  filters?: InputMaybe<ResourceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RoomSubscriptionsArgs = {
  filters?: InputMaybe<SubscriptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RoomAllowedFiltersDynamicZone =
  | ComponentFiltersComplexityFilter
  | ComponentFiltersContentTypeFilter
  | ComponentFiltersDurationFilter
  | ComponentFiltersFavoriteFilter
  | ComponentFiltersGradesFilter
  | ComponentFiltersOwnerFilter
  | ComponentFiltersSharedFilter
  | ComponentFiltersSubjectsFilter
  | ComponentFiltersSubscriptionsFilter
  | ComponentFiltersTagsFilter
  | Error;

export type RoomEntity = {
  __typename?: 'RoomEntity';
  attributes?: Maybe<Room>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type RoomEntityResponse = {
  __typename?: 'RoomEntityResponse';
  data?: Maybe<RoomEntity>;
};

export type RoomEntityResponseCollection = {
  __typename?: 'RoomEntityResponseCollection';
  data: Array<RoomEntity>;
  meta: ResponseCollectionMeta;
};

export type RoomEntry = {
  __typename?: 'RoomEntry';
  activities?: Maybe<ActivityEntityResponseCollection>;
  challenges?: Maybe<ChallengeEntityResponseCollection>;
  description?: Maybe<Scalars['String']['output']>;
  enablePagination?: Maybe<Scalars['Boolean']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isTopLevel?: Maybe<Scalars['Boolean']['output']>;
  lessons?: Maybe<LessonEntityResponseCollection>;
  name?: Maybe<Scalars['String']['output']>;
  pageSize?: Maybe<Scalars['Int']['output']>;
  projects?: Maybe<ProjectEntityResponseCollection>;
  resources?: Maybe<ResourceEntityResponseCollection>;
  showHeading?: Maybe<Scalars['Boolean']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export type RoomEntryResponse = {
  __typename?: 'RoomEntryResponse';
  data?: Maybe<RoomEntry>;
  pagination?: Maybe<Scalars['JSON']['output']>;
};

export type RoomFiltersInput = {
  activities?: InputMaybe<ActivityFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<RoomFiltersInput>>>;
  challenges?: InputMaybe<ChallengeFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  enablePagination?: InputMaybe<BooleanFilterInput>;
  enabled?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isTopLevel?: InputMaybe<BooleanFilterInput>;
  lessons?: InputMaybe<LessonFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<RoomFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<RoomFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<RoomFiltersInput>>>;
  pageSize?: InputMaybe<IntFilterInput>;
  parentRoom?: InputMaybe<RoomFiltersInput>;
  projects?: InputMaybe<ProjectFiltersInput>;
  resources?: InputMaybe<ResourceFiltersInput>;
  showHeading?: InputMaybe<BooleanFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  subscriptions?: InputMaybe<SubscriptionFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type RoomInput = {
  activities?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  allowedFilters?: InputMaybe<Array<Scalars['RoomAllowedFiltersDynamicZoneInput']['input']>>;
  challenges?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  enablePagination?: InputMaybe<Scalars['Boolean']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  isTopLevel?: InputMaybe<Scalars['Boolean']['input']>;
  lessons?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  parentRoom?: InputMaybe<Scalars['ID']['input']>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  resources?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  showHeading?: InputMaybe<Scalars['Boolean']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  subscriptions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type RoomRelationResponseCollection = {
  __typename?: 'RoomRelationResponseCollection';
  data: Array<RoomEntity>;
};

export type School = {
  __typename?: 'School';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  district?: Maybe<DistrictEntityResponse>;
  name: Scalars['String']['output'];
  subscriptions?: Maybe<Array<Maybe<ComponentBusinessRelatedSubscription>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SchoolSubscriptionsArgs = {
  filters?: InputMaybe<ComponentBusinessRelatedSubscriptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SchoolEntity = {
  __typename?: 'SchoolEntity';
  attributes?: Maybe<School>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SchoolEntityResponse = {
  __typename?: 'SchoolEntityResponse';
  data?: Maybe<SchoolEntity>;
};

export type SchoolEntityResponseCollection = {
  __typename?: 'SchoolEntityResponseCollection';
  data: Array<SchoolEntity>;
  meta: ResponseCollectionMeta;
};

export type SchoolFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SchoolFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  district?: InputMaybe<DistrictFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SchoolFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SchoolFiltersInput>>>;
  subscriptions?: InputMaybe<ComponentBusinessRelatedSubscriptionFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SchoolInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subscriptions?: InputMaybe<Array<InputMaybe<ComponentBusinessRelatedSubscriptionInput>>>;
};

export type Scope = {
  __typename?: 'Scope';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ScopeEntity = {
  __typename?: 'ScopeEntity';
  attributes?: Maybe<Scope>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ScopeEntityResponse = {
  __typename?: 'ScopeEntityResponse';
  data?: Maybe<ScopeEntity>;
};

export type ScopeEntityResponseCollection = {
  __typename?: 'ScopeEntityResponseCollection';
  data: Array<ScopeEntity>;
  meta: ResponseCollectionMeta;
};

export type ScopeFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ScopeFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ScopeFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ScopeFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ScopeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ScopeRelationResponseCollection = {
  __typename?: 'ScopeRelationResponseCollection';
  data: Array<ScopeEntity>;
};

export type SearchResponse = {
  __typename?: 'SearchResponse';
  activities?: Maybe<ActivityEntityResponseCollection>;
  lessons?: Maybe<LessonEntityResponseCollection>;
  projects?: Maybe<ProjectEntityResponseCollection>;
  resources?: Maybe<ResourceEntityResponseCollection>;
};

export type SearchResponseActivitiesArgs = {
  filters?: InputMaybe<ActivityFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
};

export type SearchResponseLessonsArgs = {
  filters?: InputMaybe<LessonFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
};

export type SearchResponseProjectsArgs = {
  filters?: InputMaybe<ProjectFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
};

export type SearchResponseResourcesArgs = {
  filters?: InputMaybe<ResourceFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
};

export type Section = {
  __typename?: 'Section';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  grades?: Maybe<GradeRelationResponseCollection>;
  name: Scalars['String']['output'];
  owner?: Maybe<UsersPermissionsUserEntityResponse>;
  parentSection?: Maybe<SectionEntityResponse>;
  students?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  subjects?: Maybe<SubjectRelationResponseCollection>;
  teachers?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SectionGradesArgs = {
  filters?: InputMaybe<GradeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SectionStudentsArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SectionSubjectsArgs = {
  filters?: InputMaybe<SubjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SectionTeachersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SectionDetails = {
  __typename?: 'SectionDetails';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  studentsCount?: Maybe<Scalars['Int']['output']>;
  teachersCount?: Maybe<Scalars['Int']['output']>;
};

export type SectionDetailsEntity = {
  __typename?: 'SectionDetailsEntity';
  attributes?: Maybe<SectionDetails>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SectionEntity = {
  __typename?: 'SectionEntity';
  attributes?: Maybe<Section>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SectionEntityResponse = {
  __typename?: 'SectionEntityResponse';
  data?: Maybe<SectionEntity>;
};

export type SectionEntityResponseCollection = {
  __typename?: 'SectionEntityResponseCollection';
  data: Array<SectionEntity>;
  meta: ResponseCollectionMeta;
};

export type SectionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SectionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  enabled?: InputMaybe<BooleanFilterInput>;
  externalId?: InputMaybe<StringFilterInput>;
  grades?: InputMaybe<GradeFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SectionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SectionFiltersInput>>>;
  owner?: InputMaybe<UsersPermissionsUserFiltersInput>;
  parentSection?: InputMaybe<SectionFiltersInput>;
  students?: InputMaybe<UsersPermissionsUserFiltersInput>;
  subjects?: InputMaybe<SubjectFiltersInput>;
  teachers?: InputMaybe<UsersPermissionsUserFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SectionInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  grades?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['ID']['input']>;
  parentSection?: InputMaybe<Scalars['ID']['input']>;
  students?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  teachers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type SectionsDetailsResponseCollection = {
  __typename?: 'SectionsDetailsResponseCollection';
  data: Array<Maybe<SectionDetailsEntity>>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  containsi?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  eqi?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nei?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notContainsi?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Subject = {
  __typename?: 'Subject';
  activities?: Maybe<ActivityRelationResponseCollection>;
  code: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lessons?: Maybe<LessonRelationResponseCollection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<SubjectRelationResponseCollection>;
  name: Scalars['String']['output'];
  ordering?: Maybe<Scalars['Int']['output']>;
  projects?: Maybe<ProjectRelationResponseCollection>;
  resources?: Maybe<ResourceRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubjectActivitiesArgs = {
  filters?: InputMaybe<ActivityFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SubjectLessonsArgs = {
  filters?: InputMaybe<LessonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SubjectLocalizationsArgs = {
  filters?: InputMaybe<SubjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SubjectProjectsArgs = {
  filters?: InputMaybe<ProjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SubjectResourcesArgs = {
  filters?: InputMaybe<ResourceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SubjectEntity = {
  __typename?: 'SubjectEntity';
  attributes?: Maybe<Subject>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SubjectEntityResponse = {
  __typename?: 'SubjectEntityResponse';
  data?: Maybe<SubjectEntity>;
};

export type SubjectEntityResponseCollection = {
  __typename?: 'SubjectEntityResponseCollection';
  data: Array<SubjectEntity>;
  meta: ResponseCollectionMeta;
};

export type SubjectFiltersInput = {
  activities?: InputMaybe<ActivityFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<SubjectFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  lessons?: InputMaybe<LessonFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<SubjectFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SubjectFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SubjectFiltersInput>>>;
  ordering?: InputMaybe<IntFilterInput>;
  projects?: InputMaybe<ProjectFiltersInput>;
  resources?: InputMaybe<ResourceFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SubjectInput = {
  activities?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  code?: InputMaybe<Scalars['String']['input']>;
  lessons?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  ordering?: InputMaybe<Scalars['Int']['input']>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  resources?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type SubjectRelationResponseCollection = {
  __typename?: 'SubjectRelationResponseCollection';
  data: Array<SubjectEntity>;
};

export type Subscription = {
  __typename?: 'Subscription';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  scopes?: Maybe<ScopeRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SubscriptionScopesArgs = {
  filters?: InputMaybe<ScopeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SubscriptionEntity = {
  __typename?: 'SubscriptionEntity';
  attributes?: Maybe<Subscription>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SubscriptionEntityResponse = {
  __typename?: 'SubscriptionEntityResponse';
  data?: Maybe<SubscriptionEntity>;
};

export type SubscriptionEntityResponseCollection = {
  __typename?: 'SubscriptionEntityResponseCollection';
  data: Array<SubscriptionEntity>;
  meta: ResponseCollectionMeta;
};

export type SubscriptionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SubscriptionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SubscriptionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SubscriptionFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  scopes?: InputMaybe<ScopeFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SubscriptionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  scopes?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type SubscriptionRelationResponseCollection = {
  __typename?: 'SubscriptionRelationResponseCollection';
  data: Array<SubscriptionEntity>;
};

export type Tag = {
  __typename?: 'Tag';
  activities?: Maybe<ActivityRelationResponseCollection>;
  context?: Maybe<Enum_Tag_Context>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lessons?: Maybe<LessonRelationResponseCollection>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<TagRelationResponseCollection>;
  name: Scalars['String']['output'];
  projects?: Maybe<ProjectRelationResponseCollection>;
  resources?: Maybe<ResourceRelationResponseCollection>;
  slug: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TagActivitiesArgs = {
  filters?: InputMaybe<ActivityFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TagLessonsArgs = {
  filters?: InputMaybe<LessonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TagLocalizationsArgs = {
  filters?: InputMaybe<TagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TagProjectsArgs = {
  filters?: InputMaybe<ProjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TagResourcesArgs = {
  filters?: InputMaybe<ResourceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TagEntity = {
  __typename?: 'TagEntity';
  attributes?: Maybe<Tag>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type TagEntityResponse = {
  __typename?: 'TagEntityResponse';
  data?: Maybe<TagEntity>;
};

export type TagEntityResponseCollection = {
  __typename?: 'TagEntityResponseCollection';
  data: Array<TagEntity>;
  meta: ResponseCollectionMeta;
};

export type TagFiltersInput = {
  activities?: InputMaybe<ActivityFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<TagFiltersInput>>>;
  context?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  lessons?: InputMaybe<LessonFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<TagFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<TagFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TagFiltersInput>>>;
  projects?: InputMaybe<ProjectFiltersInput>;
  resources?: InputMaybe<ResourceFiltersInput>;
  slug?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TagInput = {
  activities?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  context?: InputMaybe<Enum_Tag_Context>;
  lessons?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  projects?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  resources?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type TagRelationResponseCollection = {
  __typename?: 'TagRelationResponseCollection';
  data: Array<TagEntity>;
};

export type TestLoginInput = {
  password: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type Tutorial = {
  __typename?: 'Tutorial';
  collapsible: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  editorType: Enum_Tutorial_Editortype;
  mainImage: UploadFileEntityResponse;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  steps?: Maybe<Array<Maybe<TutorialStepsDynamicZone>>>;
  title: Scalars['String']['output'];
  tutorialCategories?: Maybe<TutorialCategoryRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  visibleToRoles?: Maybe<UsersPermissionsRoleRelationResponseCollection>;
};

export type TutorialTutorialCategoriesArgs = {
  filters?: InputMaybe<TutorialCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TutorialVisibleToRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TutorialCategory = {
  __typename?: 'TutorialCategory';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  order: Scalars['Int']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  tutorials?: Maybe<TutorialRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TutorialCategoryTutorialsArgs = {
  filters?: InputMaybe<TutorialFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TutorialCategoryEntity = {
  __typename?: 'TutorialCategoryEntity';
  attributes?: Maybe<TutorialCategory>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type TutorialCategoryEntityResponse = {
  __typename?: 'TutorialCategoryEntityResponse';
  data?: Maybe<TutorialCategoryEntity>;
};

export type TutorialCategoryEntityResponseCollection = {
  __typename?: 'TutorialCategoryEntityResponseCollection';
  data: Array<TutorialCategoryEntity>;
  meta: ResponseCollectionMeta;
};

export type TutorialCategoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<TutorialCategoryFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<TutorialCategoryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TutorialCategoryFiltersInput>>>;
  order?: InputMaybe<IntFilterInput>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  tutorials?: InputMaybe<TutorialFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type TutorialCategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  tutorials?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type TutorialCategoryRelationResponseCollection = {
  __typename?: 'TutorialCategoryRelationResponseCollection';
  data: Array<TutorialCategoryEntity>;
};

export type TutorialEntity = {
  __typename?: 'TutorialEntity';
  attributes?: Maybe<Tutorial>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type TutorialEntityResponse = {
  __typename?: 'TutorialEntityResponse';
  data?: Maybe<TutorialEntity>;
};

export type TutorialEntityResponseCollection = {
  __typename?: 'TutorialEntityResponseCollection';
  data: Array<TutorialEntity>;
  meta: ResponseCollectionMeta;
};

export type TutorialFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<TutorialFiltersInput>>>;
  collapsible?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  editorType?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<TutorialFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TutorialFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  tutorialCategories?: InputMaybe<TutorialCategoryFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  visibleToRoles?: InputMaybe<UsersPermissionsRoleFiltersInput>;
};

export type TutorialInput = {
  collapsible?: InputMaybe<Scalars['Boolean']['input']>;
  editorType?: InputMaybe<Enum_Tutorial_Editortype>;
  mainImage?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  steps?: InputMaybe<Array<Scalars['TutorialStepsDynamicZoneInput']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  tutorialCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  visibleToRoles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type TutorialRelationResponseCollection = {
  __typename?: 'TutorialRelationResponseCollection';
  data: Array<TutorialEntity>;
};

export type TutorialStepsDynamicZone =
  | ComponentItemsTutorialImageStep
  | ComponentItemsTutorialTextStep
  | ComponentItemsTutorialVideoStep
  | Error;

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  ext?: Maybe<Scalars['String']['output']>;
  formats?: Maybe<Scalars['JSON']['output']>;
  hash: Scalars['String']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  provider_metadata?: Maybe<Scalars['JSON']['output']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  ext?: InputMaybe<Scalars['String']['input']>;
  folder?: InputMaybe<Scalars['ID']['input']>;
  folderPath?: InputMaybe<Scalars['String']['input']>;
  formats?: InputMaybe<Scalars['JSON']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_metadata?: InputMaybe<Scalars['JSON']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String']['output'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String']['output'];
  pathId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  pathId?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UserEditorProjectsCountResponse = {
  __typename?: 'UserEditorProjectsCountResponse';
  data?: Maybe<Scalars['Int']['output']>;
};

export type UserTag = {
  __typename?: 'UserTag';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};

export type UserTagUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UserTagEntity = {
  __typename?: 'UserTagEntity';
  attributes?: Maybe<UserTag>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UserTagEntityResponse = {
  __typename?: 'UserTagEntityResponse';
  data?: Maybe<UserTagEntity>;
};

export type UserTagEntityResponseCollection = {
  __typename?: 'UserTagEntityResponseCollection';
  data: Array<UserTagEntity>;
  meta: ResponseCollectionMeta;
};

export type UserTagFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UserTagFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UserTagFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UserTagFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UserTagInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UserTagRelationResponseCollection = {
  __typename?: 'UserTagRelationResponseCollection';
  data: Array<UserTagEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String']['input'];
  password: Scalars['String']['input'];
  provider?: Scalars['String']['input'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']['output']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  tutorials?: Maybe<TutorialRelationResponseCollection>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};

export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleTutorialsArgs = {
  filters?: InputMaybe<TutorialFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  tutorials?: InputMaybe<TutorialFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  tutorials?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type UsersPermissionsRoleRelationResponseCollection = {
  __typename?: 'UsersPermissionsRoleRelationResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean']['output'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  district?: Maybe<DistrictEntityResponse>;
  email: Scalars['String']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  legacyId?: Maybe<Scalars['String']['output']>;
  legacyPassword?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  schools?: Maybe<Array<Maybe<ComponentBusinessSchoolRole>>>;
  subscriptions?: Maybe<Array<Maybe<ComponentBusinessRelatedSubscription>>>;
  tags?: Maybe<UserTagRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type UsersPermissionsUserSchoolsArgs = {
  filters?: InputMaybe<ComponentBusinessSchoolRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsUserSubscriptionsArgs = {
  filters?: InputMaybe<ComponentBusinessRelatedSubscriptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsUserTagsArgs = {
  filters?: InputMaybe<UserTagFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  district?: InputMaybe<DistrictFiltersInput>;
  email?: InputMaybe<StringFilterInput>;
  externalId?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  legacyId?: InputMaybe<StringFilterInput>;
  legacyPassword?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  schools?: InputMaybe<ComponentBusinessSchoolRoleFiltersInput>;
  subscriptions?: InputMaybe<ComponentBusinessRelatedSubscriptionFiltersInput>;
  tags?: InputMaybe<UserTagFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationToken?: InputMaybe<Scalars['String']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  district?: InputMaybe<Scalars['ID']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  legacyId?: InputMaybe<Scalars['String']['input']>;
  legacyPassword?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['ID']['input']>;
  schools?: InputMaybe<Array<InputMaybe<ComponentBusinessSchoolRoleInput>>>;
  subscriptions?: InputMaybe<Array<InputMaybe<ComponentBusinessRelatedSubscriptionInput>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type GetFeatureQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetFeatureQuery = {
  __typename?: 'Query';
  feature?: {
    __typename?: 'FeatureEntityResponse';
    data?: {
      __typename?: 'FeatureEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Feature';
        name: string;
        description?: string | null;
        enabled?: boolean | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null;
    } | null;
  } | null;
};

export type GetFeatureByNameQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type GetFeatureByNameQuery = {
  __typename?: 'Query';
  features?: {
    __typename?: 'FeatureEntityResponseCollection';
    data: Array<{
      __typename?: 'FeatureEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Feature';
        name: string;
        description?: string | null;
        enabled?: boolean | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null;
    }>;
    meta: {
      __typename?: 'ResponseCollectionMeta';
      pagination: { __typename?: 'Pagination'; page: number; pageSize: number; total: number; pageCount: number };
    };
  } | null;
};

export type GetFeaturesQueryVariables = Exact<{ [key: string]: never }>;

export type GetFeaturesQuery = {
  __typename?: 'Query';
  features?: {
    __typename?: 'FeatureEntityResponseCollection';
    data: Array<{
      __typename?: 'FeatureEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Feature';
        name: string;
        description?: string | null;
        enabled?: boolean | null;
        createdAt?: any | null;
        updatedAt?: any | null;
      } | null;
    }>;
    meta: {
      __typename?: 'ResponseCollectionMeta';
      pagination: { __typename?: 'Pagination'; page: number; pageSize: number; total: number; pageCount: number };
    };
  } | null;
};

export type ActionFragment = {
  __typename?: 'ComponentAtomsAction';
  title?: string | null;
  action: string;
  actionTitle: string;
} & { ' $fragmentName'?: 'ActionFragment' };

export type Linkable_ListFragment = {
  __typename?: 'ComponentAtomsLinkableList';
  title: string;
  link?: string | null;
  anchor?: string | null;
  description?: string | null;
} & { ' $fragmentName'?: 'Linkable_ListFragment' };

export type DurationFragment = {
  __typename?: 'ComponentAtomsTimeSelector';
  hours?: number | null;
  minutes?: number | null;
} & { ' $fragmentName'?: 'DurationFragment' };

export type GoalsFragment = { __typename?: 'ComponentContentLearningGoals'; body: string } & {
  ' $fragmentName'?: 'GoalsFragment';
};

export type LevelFragment = { __typename?: 'ComponentContentLevel'; body?: Enum_Componentcontentlevel_Body | null } & {
  ' $fragmentName'?: 'LevelFragment';
};

export type MaterialsFragment = { __typename?: 'ComponentContentRequireMaterials'; body: string } & {
  ' $fragmentName'?: 'MaterialsFragment';
};

export type CollectionFragment = {
  __typename?: 'ComponentDashboardCollections';
  title: string;
  content?: string | null;
  tags: Array<{
    __typename?: 'ComponentContentListByTag';
    id: string;
    name: string;
    limit?: number | null;
    tag?: {
      __typename?: 'TagEntityResponse';
      data?: {
        __typename?: 'TagEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'Tag';
          name: string;
          slug: string;
          projects?:
            | ({ __typename?: 'ProjectRelationResponseCollection' } & {
                ' $fragmentRefs'?: { Projects_OverviewFragment: Projects_OverviewFragment };
              })
            | null;
          lessons?:
            | ({ __typename?: 'LessonRelationResponseCollection' } & {
                ' $fragmentRefs'?: { Lessons_OverviewFragment: Lessons_OverviewFragment };
              })
            | null;
          activities?:
            | ({ __typename?: 'ActivityRelationResponseCollection' } & {
                ' $fragmentRefs'?: { Activities_OverviewFragment: Activities_OverviewFragment };
              })
            | null;
          resources?:
            | ({ __typename?: 'ResourceRelationResponseCollection' } & {
                ' $fragmentRefs'?: { Resources_OverviewFragment: Resources_OverviewFragment };
              })
            | null;
        } | null;
      } | null;
    } | null;
  } | null>;
} & { ' $fragmentName'?: 'CollectionFragment' };

export type Activities_OverviewFragment = {
  __typename?: 'ActivityRelationResponseCollection';
  data: Array<{
    __typename: 'ActivityEntity';
    id?: string | null;
    attributes?: {
      __typename?: 'Activity';
      name: string;
      preview?:
        | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
        | null;
      preparationDuration?:
        | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
            ' $fragmentRefs'?: { DurationFragment: DurationFragment };
          })
        | null;
      learningDuration?:
        | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
            ' $fragmentRefs'?: { DurationFragment: DurationFragment };
          })
        | null;
    } | null;
  }>;
} & { ' $fragmentName'?: 'Activities_OverviewFragment' };

export type Challenges_OverviewFragment = {
  __typename?: 'ChallengeRelationResponseCollection';
  data: Array<{
    __typename: 'ChallengeEntity';
    id?: string | null;
    attributes?: { __typename?: 'Challenge'; name: string } | null;
  }>;
} & { ' $fragmentName'?: 'Challenges_OverviewFragment' };

export type Lessons_OverviewFragment = {
  __typename?: 'LessonRelationResponseCollection';
  data: Array<{
    __typename: 'LessonEntity';
    id?: string | null;
    attributes?: {
      __typename?: 'Lesson';
      name: string;
      preview?:
        | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
        | null;
      preparationDuration?:
        | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
            ' $fragmentRefs'?: { DurationFragment: DurationFragment };
          })
        | null;
      learningDuration?:
        | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
            ' $fragmentRefs'?: { DurationFragment: DurationFragment };
          })
        | null;
    } | null;
  }>;
} & { ' $fragmentName'?: 'Lessons_OverviewFragment' };

export type Project_OverviewFragment = {
  __typename?: 'ProjectEntityResponse';
  data?: {
    __typename: 'ProjectEntity';
    id?: string | null;
    attributes?: {
      __typename?: 'Project';
      name: string;
      preview?:
        | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
        | null;
      preparationDuration?:
        | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
            ' $fragmentRefs'?: { DurationFragment: DurationFragment };
          })
        | null;
      learningDuration?:
        | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
            ' $fragmentRefs'?: { DurationFragment: DurationFragment };
          })
        | null;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'Project_OverviewFragment' };

export type Projects_OverviewFragment = {
  __typename?: 'ProjectRelationResponseCollection';
  data: Array<{
    __typename: 'ProjectEntity';
    id?: string | null;
    attributes?: {
      __typename?: 'Project';
      name: string;
      preview?:
        | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
        | null;
      preparationDuration?:
        | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
            ' $fragmentRefs'?: { DurationFragment: DurationFragment };
          })
        | null;
      learningDuration?:
        | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
            ' $fragmentRefs'?: { DurationFragment: DurationFragment };
          })
        | null;
    } | null;
  }>;
} & { ' $fragmentName'?: 'Projects_OverviewFragment' };

export type Resources_OverviewFragment = {
  __typename?: 'ResourceRelationResponseCollection';
  data: Array<{
    __typename: 'ResourceEntity';
    id?: string | null;
    attributes?: {
      __typename?: 'Resource';
      name: string;
      preview?:
        | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
        | null;
    } | null;
  }>;
} & { ' $fragmentName'?: 'Resources_OverviewFragment' };

export type FileFragment = {
  __typename?: 'UploadFileEntityResponse';
  data?: {
    __typename?: 'UploadFileEntity';
    attributes?: {
      __typename?: 'UploadFile';
      name: string;
      caption?: string | null;
      alternativeText?: string | null;
      url: string;
      previewUrl?: string | null;
      formats?: any | null;
      mime: string;
    } | null;
  } | null;
} & { ' $fragmentName'?: 'FileFragment' };

export type FilesFragment = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<{
    __typename?: 'UploadFileEntity';
    attributes?: {
      __typename?: 'UploadFile';
      name: string;
      caption?: string | null;
      alternativeText?: string | null;
      url: string;
      previewUrl?: string | null;
      formats?: any | null;
      mime: string;
    } | null;
  }>;
} & { ' $fragmentName'?: 'FilesFragment' };

export type Related_GradesFragment = {
  __typename?: 'GradeRelationResponseCollection';
  data: Array<{
    __typename?: 'GradeEntity';
    id?: string | null;
    attributes?: { __typename?: 'Grade'; name: string } | null;
  }>;
} & { ' $fragmentName'?: 'Related_GradesFragment' };

export type Toc_EntryFragment = {
  __typename?: 'ComponentItemsToCEntry';
  entry?: {
    __typename?: 'ComponentAtomsTextWithTime';
    title: string;
    link?: string | null;
    hours?: number | null;
    minutes?: number | null;
  } | null;
  items?: Array<{
    __typename?: 'ComponentAtomsTextWithTime';
    title: string;
    link?: string | null;
    hours?: number | null;
    minutes?: number | null;
  } | null> | null;
} & { ' $fragmentName'?: 'Toc_EntryFragment' };

export type With_Tutorial_Step_Available_ActionsFragment = {
  __typename?: 'ComponentItemsTutorialStepAvailableActions';
  id: string;
  action?: string | null;
  data?: any | null;
} & { ' $fragmentName'?: 'With_Tutorial_Step_Available_ActionsFragment' };

export type With_Tutorial_Step_Needed_ActionsFragment = {
  __typename?: 'ComponentItemsTutorialStepNeededActions';
  id: string;
  action?: string | null;
  data?: any | null;
} & { ' $fragmentName'?: 'With_Tutorial_Step_Needed_ActionsFragment' };

export type Tutorial_Step_Common_FieldsFragment = {
  __typename?: 'ComponentItemsTutorialStepCommonFields';
  id: string;
  title: string;
  showProgress: boolean;
  showOverlay: boolean;
  text?: string | null;
  timeDelay?: number | null;
  backButtonText?: string | null;
  backButtonAction: Enum_Componentitemstutorialstepcommonfields_Backbuttonaction;
  backButtonUrl?: string | null;
  nextButtonText?: string | null;
  nextButtonAction: Enum_Componentitemstutorialstepcommonfields_Nextbuttonaction;
  nextButtonUrl?: string | null;
} & { ' $fragmentName'?: 'Tutorial_Step_Common_FieldsFragment' };

export type Common_SectionFragment = {
  __typename?: 'ComponentSectionsCommonSection';
  title: string;
  description?: string | null;
  enumerable?: boolean | null;
  content?: string | null;
  captions?: Array<
    | ({ __typename?: 'ComponentAtomsLinkableList' } & {
        ' $fragmentRefs'?: { Linkable_ListFragment: Linkable_ListFragment };
      })
    | null
  > | null;
  buttons?: Array<
    | ({ __typename?: 'ComponentAtomsLinkableList' } & {
        ' $fragmentRefs'?: { Linkable_ListFragment: Linkable_ListFragment };
      })
    | null
  > | null;
  preparationDuration?:
    | ({ __typename?: 'ComponentAtomsTimeSelector' } & { ' $fragmentRefs'?: { DurationFragment: DurationFragment } })
    | null;
  learningDuration?:
    | ({ __typename?: 'ComponentAtomsTimeSelector' } & { ' $fragmentRefs'?: { DurationFragment: DurationFragment } })
    | null;
  assets?: {
    __typename?: 'ComponentSharedAssets';
    files?:
      | ({ __typename?: 'UploadFileRelationResponseCollection' } & {
          ' $fragmentRefs'?: { FilesFragment: FilesFragment };
        })
      | null;
  } | null;
} & { ' $fragmentName'?: 'Common_SectionFragment' };

export type Lesson_Plan_SectionFragment = {
  __typename?: 'ComponentSectionsLessonPlanSection';
  title: string;
  description?: string | null;
  enumerable?: boolean | null;
  content?: string | null;
  captions?: Array<
    | ({ __typename?: 'ComponentAtomsLinkableList' } & {
        ' $fragmentRefs'?: { Linkable_ListFragment: Linkable_ListFragment };
      })
    | null
  > | null;
  buttons?: Array<
    | ({ __typename?: 'ComponentAtomsLinkableList' } & {
        ' $fragmentRefs'?: { Linkable_ListFragment: Linkable_ListFragment };
      })
    | null
  > | null;
  preparationDuration?:
    | ({ __typename?: 'ComponentAtomsTimeSelector' } & { ' $fragmentRefs'?: { DurationFragment: DurationFragment } })
    | null;
  learningDuration?:
    | ({ __typename?: 'ComponentAtomsTimeSelector' } & { ' $fragmentRefs'?: { DurationFragment: DurationFragment } })
    | null;
  assets?: {
    __typename?: 'ComponentSharedAssets';
    files?:
      | ({ __typename?: 'UploadFileRelationResponseCollection' } & {
          ' $fragmentRefs'?: { FilesFragment: FilesFragment };
        })
      | null;
  } | null;
} & { ' $fragmentName'?: 'Lesson_Plan_SectionFragment' };

export type Lesson_Run_SectionFragment = {
  __typename?: 'ComponentSectionsLessonRunSection';
  title: string;
  description?: string | null;
  enumerable?: boolean | null;
  content?: string | null;
  captions?: Array<
    | ({ __typename?: 'ComponentAtomsLinkableList' } & {
        ' $fragmentRefs'?: { Linkable_ListFragment: Linkable_ListFragment };
      })
    | null
  > | null;
  buttons?: Array<
    | ({ __typename?: 'ComponentAtomsLinkableList' } & {
        ' $fragmentRefs'?: { Linkable_ListFragment: Linkable_ListFragment };
      })
    | null
  > | null;
  preparationDuration?:
    | ({ __typename?: 'ComponentAtomsTimeSelector' } & { ' $fragmentRefs'?: { DurationFragment: DurationFragment } })
    | null;
  learningDuration?:
    | ({ __typename?: 'ComponentAtomsTimeSelector' } & { ' $fragmentRefs'?: { DurationFragment: DurationFragment } })
    | null;
  assets?: {
    __typename?: 'ComponentSharedAssets';
    files?:
      | ({ __typename?: 'UploadFileRelationResponseCollection' } & {
          ' $fragmentRefs'?: { FilesFragment: FilesFragment };
        })
      | null;
  } | null;
} & { ' $fragmentName'?: 'Lesson_Run_SectionFragment' };

export type Project_Delivery_SectionFragment = {
  __typename?: 'ComponentSectionsProjectDeliverySection';
  title: string;
  description?: string | null;
  enumerable?: boolean | null;
  content?: string | null;
  captions?: Array<
    | ({ __typename?: 'ComponentAtomsLinkableList' } & {
        ' $fragmentRefs'?: { Linkable_ListFragment: Linkable_ListFragment };
      })
    | null
  > | null;
  buttons?: Array<
    | ({ __typename?: 'ComponentAtomsLinkableList' } & {
        ' $fragmentRefs'?: { Linkable_ListFragment: Linkable_ListFragment };
      })
    | null
  > | null;
  preparationDuration?:
    | ({ __typename?: 'ComponentAtomsTimeSelector' } & { ' $fragmentRefs'?: { DurationFragment: DurationFragment } })
    | null;
  learningDuration?:
    | ({ __typename?: 'ComponentAtomsTimeSelector' } & { ' $fragmentRefs'?: { DurationFragment: DurationFragment } })
    | null;
  assets?: {
    __typename?: 'ComponentSharedAssets';
    files?:
      | ({ __typename?: 'UploadFileRelationResponseCollection' } & {
          ' $fragmentRefs'?: { FilesFragment: FilesFragment };
        })
      | null;
  } | null;
} & { ' $fragmentName'?: 'Project_Delivery_SectionFragment' };

export type Project_Evaluation_SectionFragment = {
  __typename?: 'ComponentSectionsProjectEvaluationSection';
  title: string;
  description?: string | null;
  enumerable?: boolean | null;
  content?: string | null;
  captions?: Array<
    | ({ __typename?: 'ComponentAtomsLinkableList' } & {
        ' $fragmentRefs'?: { Linkable_ListFragment: Linkable_ListFragment };
      })
    | null
  > | null;
  buttons?: Array<
    | ({ __typename?: 'ComponentAtomsLinkableList' } & {
        ' $fragmentRefs'?: { Linkable_ListFragment: Linkable_ListFragment };
      })
    | null
  > | null;
  preparationDuration?:
    | ({ __typename?: 'ComponentAtomsTimeSelector' } & { ' $fragmentRefs'?: { DurationFragment: DurationFragment } })
    | null;
  learningDuration?:
    | ({ __typename?: 'ComponentAtomsTimeSelector' } & { ' $fragmentRefs'?: { DurationFragment: DurationFragment } })
    | null;
  assets?: {
    __typename?: 'ComponentSharedAssets';
    files?:
      | ({ __typename?: 'UploadFileRelationResponseCollection' } & {
          ' $fragmentRefs'?: { FilesFragment: FilesFragment };
        })
      | null;
  } | null;
} & { ' $fragmentName'?: 'Project_Evaluation_SectionFragment' };

export type Project_Plan_SectionFragment = {
  __typename?: 'ComponentSectionsProjectPlanSection';
  title: string;
  description?: string | null;
  enumerable?: boolean | null;
  content?: string | null;
  captions?: Array<
    | ({ __typename?: 'ComponentAtomsLinkableList' } & {
        ' $fragmentRefs'?: { Linkable_ListFragment: Linkable_ListFragment };
      })
    | null
  > | null;
  buttons?: Array<
    | ({ __typename?: 'ComponentAtomsLinkableList' } & {
        ' $fragmentRefs'?: { Linkable_ListFragment: Linkable_ListFragment };
      })
    | null
  > | null;
  preparationDuration?:
    | ({ __typename?: 'ComponentAtomsTimeSelector' } & { ' $fragmentRefs'?: { DurationFragment: DurationFragment } })
    | null;
  learningDuration?:
    | ({ __typename?: 'ComponentAtomsTimeSelector' } & { ' $fragmentRefs'?: { DurationFragment: DurationFragment } })
    | null;
  assets?: {
    __typename?: 'ComponentSharedAssets';
    files?:
      | ({ __typename?: 'UploadFileRelationResponseCollection' } & {
          ' $fragmentRefs'?: { FilesFragment: FilesFragment };
        })
      | null;
  } | null;
} & { ' $fragmentName'?: 'Project_Plan_SectionFragment' };

export type GalleryFragment = {
  __typename?: 'ComponentSharedGallery';
  main: { __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } };
  files?:
    | ({ __typename?: 'UploadFileRelationResponseCollection' } & {
        ' $fragmentRefs'?: { FilesFragment: FilesFragment };
      })
    | null;
} & { ' $fragmentName'?: 'GalleryFragment' };

export type Related_SubjectsFragment = {
  __typename?: 'SubjectRelationResponseCollection';
  data: Array<{
    __typename?: 'SubjectEntity';
    id?: string | null;
    attributes?: { __typename?: 'Subject'; name: string; code: string } | null;
  }>;
} & { ' $fragmentName'?: 'Related_SubjectsFragment' };

export type Related_TagsFragment = {
  __typename?: 'TagRelationResponseCollection';
  data: Array<{
    __typename?: 'TagEntity';
    id?: string | null;
    attributes?: { __typename?: 'Tag'; name: string; context?: Enum_Tag_Context | null; slug: string } | null;
  }>;
} & { ' $fragmentName'?: 'Related_TagsFragment' };

export type GetActivityQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetActivityQuery = {
  __typename?: 'Query';
  activity?: {
    __typename?: 'ActivityEntityResponse';
    data?: {
      __typename?: 'ActivityEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Activity';
        name: string;
        description?: string | null;
        slug: string;
        gallery?:
          | ({ __typename?: 'ComponentSharedGallery' } & { ' $fragmentRefs'?: { GalleryFragment: GalleryFragment } })
          | null;
        goals?: Array<
          | ({ __typename?: 'ComponentContentLearningGoals' } & { ' $fragmentRefs'?: { GoalsFragment: GoalsFragment } })
          | null
        > | null;
        materials?: Array<
          | ({ __typename?: 'ComponentContentRequireMaterials' } & {
              ' $fragmentRefs'?: { MaterialsFragment: MaterialsFragment };
            })
          | null
        > | null;
        level?:
          | ({ __typename?: 'ComponentContentLevel' } & { ' $fragmentRefs'?: { LevelFragment: LevelFragment } })
          | null;
        preparationDuration?:
          | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
              ' $fragmentRefs'?: { DurationFragment: DurationFragment };
            })
          | null;
        learningDuration?:
          | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
              ' $fragmentRefs'?: { DurationFragment: DurationFragment };
            })
          | null;
        grades?:
          | ({ __typename?: 'GradeRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_GradesFragment: Related_GradesFragment };
            })
          | null;
        subjects?:
          | ({ __typename?: 'SubjectRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_SubjectsFragment: Related_SubjectsFragment };
            })
          | null;
        tags?:
          | ({ __typename?: 'TagRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_TagsFragment: Related_TagsFragment };
            })
          | null;
        project?:
          | ({ __typename?: 'ProjectEntityResponse' } & {
              ' $fragmentRefs'?: { Project_OverviewFragment: Project_OverviewFragment };
            })
          | null;
        linkedItems?: Array<
          | {
              __typename: 'ComponentItemsLinkedChallenges';
              challenges?:
                | ({ __typename?: 'ChallengeRelationResponseCollection' } & {
                    ' $fragmentRefs'?: { Challenges_OverviewFragment: Challenges_OverviewFragment };
                  })
                | null;
            }
          | {
              __typename: 'ComponentItemsLinkedLessons';
              lessons?:
                | ({ __typename?: 'LessonRelationResponseCollection' } & {
                    ' $fragmentRefs'?: { Lessons_OverviewFragment: Lessons_OverviewFragment };
                  })
                | null;
            }
          | {
              __typename: 'ComponentItemsLinkedProjects';
              projects?:
                | ({ __typename?: 'ProjectRelationResponseCollection' } & {
                    ' $fragmentRefs'?: { Projects_OverviewFragment: Projects_OverviewFragment };
                  })
                | null;
            }
          | { __typename: 'Error' }
          | null
        > | null;
        sections?: Array<
          | ({ __typename: 'ComponentSectionsCommonSection' } & {
              ' $fragmentRefs'?: { Common_SectionFragment: Common_SectionFragment };
            })
          | { __typename: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type GetWebAppSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetWebAppSettingsQuery = {
  __typename?: 'Query';
  appsSettings?: {
    __typename?: 'AppSettingsEntityResponseCollection';
    data: Array<{
      __typename?: 'AppSettingsEntity';
      attributes?: {
        __typename?: 'AppSettings';
        key: string;
        settings?: Array<{ __typename?: 'ComponentItemsSetting'; name: string; value: string } | null> | null;
      } | null;
    }>;
  } | null;
};

export type GetDashboardQueryVariables = Exact<{ [key: string]: never }>;

export type GetDashboardQuery = {
  __typename?: 'Query';
  dashboard?: {
    __typename?: 'DashboardEntityResponse';
    data?: {
      __typename?: 'DashboardEntity';
      attributes?: {
        __typename?: 'Dashboard';
        greeting: {
          __typename?: 'ComponentDashboardGreetingSection';
          title: string;
          content?: string | null;
          libraryAction: { __typename?: 'ComponentAtomsAction' } & {
            ' $fragmentRefs'?: { ActionFragment: ActionFragment };
          };
          communityAction: { __typename?: 'ComponentAtomsAction' } & {
            ' $fragmentRefs'?: { ActionFragment: ActionFragment };
          };
          pdAction: { __typename?: 'ComponentAtomsAction' } & { ' $fragmentRefs'?: { ActionFragment: ActionFragment } };
          helpAction: { __typename?: 'ComponentAtomsAction' } & {
            ' $fragmentRefs'?: { ActionFragment: ActionFragment };
          };
        };
        collections: { __typename?: 'ComponentDashboardCollections' } & {
          ' $fragmentRefs'?: { CollectionFragment: CollectionFragment };
        };
        inspiration?: {
          __typename?: 'ComponentDashboardInspirationSection';
          title: string;
          content: string;
          inspirationAction?:
            | ({ __typename?: 'ComponentAtomsAction' } & { ' $fragmentRefs'?: { ActionFragment: ActionFragment } })
            | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type Full_Editor_ProjectFragment = {
  __typename?: 'EditorProjectEntity';
  id?: string | null;
  attributes?: {
    __typename?: 'EditorProject';
    uuid: string;
    title: string;
    type: Enum_Editorproject_Type;
    version: string;
    createdAt?: any | null;
    updatedAt?: any | null;
    editorState: any;
    previewImage?:
      | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
      | null;
  } | null;
} & { ' $fragmentName'?: 'Full_Editor_ProjectFragment' };

export type Short_Editor_ProjectFragment = {
  __typename?: 'EditorProjectEntity';
  id?: string | null;
  attributes?: {
    __typename?: 'EditorProject';
    uuid: string;
    title: string;
    type: Enum_Editorproject_Type;
    version: string;
    createdAt?: any | null;
    updatedAt?: any | null;
    previewImage?:
      | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
      | null;
  } | null;
} & { ' $fragmentName'?: 'Short_Editor_ProjectFragment' };

export type EditorProjectQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type EditorProjectQuery = {
  __typename?: 'Query';
  editorProject?: {
    __typename?: 'EditorProjectEntityResponse';
    data?:
      | ({ __typename?: 'EditorProjectEntity' } & {
          ' $fragmentRefs'?: { Full_Editor_ProjectFragment: Full_Editor_ProjectFragment };
        })
      | null;
  } | null;
};

export type SearchEditorProjectsQueryVariables = Exact<{
  filters?: InputMaybe<EditorProjectFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;

export type SearchEditorProjectsQuery = {
  __typename?: 'Query';
  editorProjects?: {
    __typename?: 'EditorProjectEntityResponseCollection';
    data: Array<
      { __typename?: 'EditorProjectEntity' } & {
        ' $fragmentRefs'?: { Short_Editor_ProjectFragment: Short_Editor_ProjectFragment };
      }
    >;
    meta: {
      __typename?: 'ResponseCollectionMeta';
      pagination: { __typename?: 'Pagination'; page: number; pageSize: number; total: number; pageCount: number };
    };
  } | null;
};

export type CreateEditorProjectMutationVariables = Exact<{
  data: EditorProjectInput;
}>;

export type CreateEditorProjectMutation = {
  __typename?: 'Mutation';
  createEditorProject?: {
    __typename?: 'EditorProjectEntityResponse';
    data?:
      | ({ __typename?: 'EditorProjectEntity' } & {
          ' $fragmentRefs'?: { Short_Editor_ProjectFragment: Short_Editor_ProjectFragment };
        })
      | null;
  } | null;
};

export type UpdateEditorProjectMutationVariables = Exact<{
  data: EditorProjectInput;
  id: Scalars['ID']['input'];
}>;

export type UpdateEditorProjectMutation = {
  __typename?: 'Mutation';
  updateEditorProject?: {
    __typename?: 'EditorProjectEntityResponse';
    data?:
      | ({ __typename?: 'EditorProjectEntity' } & {
          ' $fragmentRefs'?: { Short_Editor_ProjectFragment: Short_Editor_ProjectFragment };
        })
      | null;
  } | null;
};

export type DeleteEditorProjectMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteEditorProjectMutation = {
  __typename?: 'Mutation';
  deleteEditorProject?: {
    __typename?: 'EditorProjectEntityResponse';
    data?:
      | ({ __typename?: 'EditorProjectEntity' } & {
          ' $fragmentRefs'?: { Short_Editor_ProjectFragment: Short_Editor_ProjectFragment };
        })
      | null;
  } | null;
};

export type UserEditorProjectsCountQueryVariables = Exact<{ [key: string]: never }>;

export type UserEditorProjectsCountQuery = {
  __typename?: 'Query';
  userEditorProjectsCount?: { __typename?: 'UserEditorProjectsCountResponse'; data?: number | null } | null;
};

export type GetLessonQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetLessonQuery = {
  __typename?: 'Query';
  lesson?: {
    __typename?: 'LessonEntityResponse';
    data?: {
      __typename?: 'LessonEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Lesson';
        name: string;
        description?: string | null;
        slug: string;
        gallery?:
          | ({ __typename?: 'ComponentSharedGallery' } & { ' $fragmentRefs'?: { GalleryFragment: GalleryFragment } })
          | null;
        goals?: Array<
          | ({ __typename?: 'ComponentContentLearningGoals' } & { ' $fragmentRefs'?: { GoalsFragment: GoalsFragment } })
          | null
        > | null;
        materials?: Array<
          | ({ __typename?: 'ComponentContentRequireMaterials' } & {
              ' $fragmentRefs'?: { MaterialsFragment: MaterialsFragment };
            })
          | null
        > | null;
        level?:
          | ({ __typename?: 'ComponentContentLevel' } & { ' $fragmentRefs'?: { LevelFragment: LevelFragment } })
          | null;
        preparationDuration?:
          | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
              ' $fragmentRefs'?: { DurationFragment: DurationFragment };
            })
          | null;
        learningDuration?:
          | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
              ' $fragmentRefs'?: { DurationFragment: DurationFragment };
            })
          | null;
        grades?:
          | ({ __typename?: 'GradeRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_GradesFragment: Related_GradesFragment };
            })
          | null;
        subjects?:
          | ({ __typename?: 'SubjectRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_SubjectsFragment: Related_SubjectsFragment };
            })
          | null;
        tags?:
          | ({ __typename?: 'TagRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_TagsFragment: Related_TagsFragment };
            })
          | null;
        project?:
          | ({ __typename?: 'ProjectEntityResponse' } & {
              ' $fragmentRefs'?: { Project_OverviewFragment: Project_OverviewFragment };
            })
          | null;
        linkedItems?: Array<
          | {
              __typename: 'ComponentItemsLinkedChallenges';
              challenges?:
                | ({ __typename?: 'ChallengeRelationResponseCollection' } & {
                    ' $fragmentRefs'?: { Challenges_OverviewFragment: Challenges_OverviewFragment };
                  })
                | null;
            }
          | {
              __typename: 'ComponentItemsLinkedLessons';
              lessons?:
                | ({ __typename?: 'LessonRelationResponseCollection' } & {
                    ' $fragmentRefs'?: { Lessons_OverviewFragment: Lessons_OverviewFragment };
                  })
                | null;
            }
          | {
              __typename: 'ComponentItemsLinkedProjects';
              projects?:
                | ({ __typename?: 'ProjectRelationResponseCollection' } & {
                    ' $fragmentRefs'?: { Projects_OverviewFragment: Projects_OverviewFragment };
                  })
                | null;
            }
          | { __typename: 'Error' }
          | null
        > | null;
        sections?: Array<
          | ({ __typename: 'ComponentSectionsCommonSection' } & {
              ' $fragmentRefs'?: { Common_SectionFragment: Common_SectionFragment };
            })
          | ({ __typename: 'ComponentSectionsLessonPlanSection' } & {
              ' $fragmentRefs'?: { Lesson_Plan_SectionFragment: Lesson_Plan_SectionFragment };
            })
          | ({ __typename: 'ComponentSectionsLessonRunSection' } & {
              ' $fragmentRefs'?: { Lesson_Run_SectionFragment: Lesson_Run_SectionFragment };
            })
          | { __typename: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type GetLibraryDataQueryVariables = Exact<{
  includeProjects: Scalars['Boolean']['input'];
  includeLessons: Scalars['Boolean']['input'];
  includeActivities: Scalars['Boolean']['input'];
  includeResources: Scalars['Boolean']['input'];
  projectFilters: ProjectFiltersInput;
  lessonFilters: LessonFiltersInput;
  activityFilters: ActivityFiltersInput;
  resourceFilters: ResourceFiltersInput;
}>;

export type GetLibraryDataQuery = {
  __typename?: 'Query';
  projects?: {
    __typename?: 'ProjectEntityResponseCollection';
    data: Array<{
      __typename: 'ProjectEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Project';
        name: string;
        slug: string;
        updatedAt?: any | null;
        preview?:
          | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
          | null;
        preparationDuration?:
          | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
              ' $fragmentRefs'?: { DurationFragment: DurationFragment };
            })
          | null;
        learningDuration?:
          | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
              ' $fragmentRefs'?: { DurationFragment: DurationFragment };
            })
          | null;
        subjects?:
          | ({ __typename?: 'SubjectRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_SubjectsFragment: Related_SubjectsFragment };
            })
          | null;
        grades?:
          | ({ __typename?: 'GradeRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_GradesFragment: Related_GradesFragment };
            })
          | null;
        tags?:
          | ({ __typename?: 'TagRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_TagsFragment: Related_TagsFragment };
            })
          | null;
      } | null;
    }>;
  } | null;
  lessons?: {
    __typename?: 'LessonEntityResponseCollection';
    data: Array<{
      __typename: 'LessonEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Lesson';
        name: string;
        slug: string;
        updatedAt?: any | null;
        preview?:
          | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
          | null;
        preparationDuration?:
          | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
              ' $fragmentRefs'?: { DurationFragment: DurationFragment };
            })
          | null;
        learningDuration?:
          | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
              ' $fragmentRefs'?: { DurationFragment: DurationFragment };
            })
          | null;
        subjects?:
          | ({ __typename?: 'SubjectRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_SubjectsFragment: Related_SubjectsFragment };
            })
          | null;
        grades?:
          | ({ __typename?: 'GradeRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_GradesFragment: Related_GradesFragment };
            })
          | null;
        tags?:
          | ({ __typename?: 'TagRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_TagsFragment: Related_TagsFragment };
            })
          | null;
      } | null;
    }>;
  } | null;
  activities?: {
    __typename?: 'ActivityEntityResponseCollection';
    data: Array<{
      __typename: 'ActivityEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Activity';
        name: string;
        slug: string;
        updatedAt?: any | null;
        preview?:
          | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
          | null;
        preparationDuration?:
          | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
              ' $fragmentRefs'?: { DurationFragment: DurationFragment };
            })
          | null;
        learningDuration?:
          | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
              ' $fragmentRefs'?: { DurationFragment: DurationFragment };
            })
          | null;
        subjects?:
          | ({ __typename?: 'SubjectRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_SubjectsFragment: Related_SubjectsFragment };
            })
          | null;
        grades?:
          | ({ __typename?: 'GradeRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_GradesFragment: Related_GradesFragment };
            })
          | null;
        tags?:
          | ({ __typename?: 'TagRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_TagsFragment: Related_TagsFragment };
            })
          | null;
      } | null;
    }>;
  } | null;
  resources?: {
    __typename?: 'ResourceEntityResponseCollection';
    data: Array<{
      __typename: 'ResourceEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Resource';
        name: string;
        slug: string;
        updatedAt?: any | null;
        preview?:
          | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
          | null;
        subjects?:
          | ({ __typename?: 'SubjectRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_SubjectsFragment: Related_SubjectsFragment };
            })
          | null;
        tags?:
          | ({ __typename?: 'TagRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_TagsFragment: Related_TagsFragment };
            })
          | null;
      } | null;
    }>;
  } | null;
};

export type GetLibraryFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type GetLibraryFiltersQuery = {
  __typename?: 'Query';
  subjects?: {
    __typename?: 'SubjectEntityResponseCollection';
    data: Array<{
      __typename?: 'SubjectEntity';
      id?: string | null;
      attributes?: { __typename?: 'Subject'; name: string; code: string } | null;
    }>;
  } | null;
  grades?: {
    __typename?: 'GradeEntityResponseCollection';
    data: Array<{
      __typename?: 'GradeEntity';
      id?: string | null;
      attributes?: { __typename?: 'Grade'; name: string } | null;
    }>;
  } | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'UsersPermissionsMe';
    id: string;
    username: string;
    role?: {
      __typename?: 'UsersPermissionsMeRole';
      id: string;
      name: string;
      type?: string | null;
      description?: string | null;
    } | null;
  } | null;
};

export type GetPageQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetPageQuery = {
  __typename?: 'Query';
  pages?: {
    __typename?: 'PageEntityResponseCollection';
    data: Array<{
      __typename?: 'PageEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Page';
        title: string;
        description?: string | null;
        slug?: string | null;
        workspace?: Enum_Page_Workspace | null;
        blocks?: Array<
          | ({ __typename: 'ComponentDashboardCollections' } & {
              ' $fragmentRefs'?: { CollectionFragment: CollectionFragment };
            })
          | ({ __typename: 'ComponentSectionsCommonSection' } & {
              ' $fragmentRefs'?: { Common_SectionFragment: Common_SectionFragment };
            })
          | ({ __typename: 'ComponentSharedGallery' } & { ' $fragmentRefs'?: { GalleryFragment: GalleryFragment } })
          | {
              __typename: 'ComponentSharedMedia';
              file?:
                | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
                | null;
            }
          | { __typename: 'ComponentSharedRichText'; id: string; body?: string | null }
          | { __typename: 'Error' }
          | null
        > | null;
      } | null;
    }>;
  } | null;
};

export type GetProjectQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProjectQuery = {
  __typename?: 'Query';
  project?: {
    __typename?: 'ProjectEntityResponse';
    data?: {
      __typename?: 'ProjectEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Project';
        name: string;
        description?: string | null;
        slug: string;
        gallery?:
          | ({ __typename?: 'ComponentSharedGallery' } & { ' $fragmentRefs'?: { GalleryFragment: GalleryFragment } })
          | null;
        goals?: Array<
          | ({ __typename?: 'ComponentContentLearningGoals' } & { ' $fragmentRefs'?: { GoalsFragment: GoalsFragment } })
          | null
        > | null;
        materials?: Array<
          | ({ __typename?: 'ComponentContentRequireMaterials' } & {
              ' $fragmentRefs'?: { MaterialsFragment: MaterialsFragment };
            })
          | null
        > | null;
        level?:
          | ({ __typename?: 'ComponentContentLevel' } & { ' $fragmentRefs'?: { LevelFragment: LevelFragment } })
          | null;
        preparationDuration?:
          | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
              ' $fragmentRefs'?: { DurationFragment: DurationFragment };
            })
          | null;
        learningDuration?:
          | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
              ' $fragmentRefs'?: { DurationFragment: DurationFragment };
            })
          | null;
        grades?:
          | ({ __typename?: 'GradeRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_GradesFragment: Related_GradesFragment };
            })
          | null;
        subjects?:
          | ({ __typename?: 'SubjectRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_SubjectsFragment: Related_SubjectsFragment };
            })
          | null;
        tags?:
          | ({ __typename?: 'TagRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_TagsFragment: Related_TagsFragment };
            })
          | null;
        lessons?:
          | ({ __typename?: 'LessonRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Lessons_OverviewFragment: Lessons_OverviewFragment };
            })
          | null;
        tableOfContents?: Array<
          | ({ __typename: 'ComponentItemsToCEntry' } & { ' $fragmentRefs'?: { Toc_EntryFragment: Toc_EntryFragment } })
          | { __typename: 'Error' }
          | null
        > | null;
        linkedItems?: Array<
          | {
              __typename: 'ComponentItemsLinkedChallenges';
              challenges?:
                | ({ __typename?: 'ChallengeRelationResponseCollection' } & {
                    ' $fragmentRefs'?: { Challenges_OverviewFragment: Challenges_OverviewFragment };
                  })
                | null;
            }
          | {
              __typename: 'ComponentItemsLinkedLessons';
              lessons?:
                | ({ __typename?: 'LessonRelationResponseCollection' } & {
                    ' $fragmentRefs'?: { Lessons_OverviewFragment: Lessons_OverviewFragment };
                  })
                | null;
            }
          | {
              __typename: 'ComponentItemsLinkedProjects';
              projects?:
                | ({ __typename?: 'ProjectRelationResponseCollection' } & {
                    ' $fragmentRefs'?: { Projects_OverviewFragment: Projects_OverviewFragment };
                  })
                | null;
            }
          | { __typename: 'Error' }
          | null
        > | null;
        sections?: Array<
          | ({ __typename: 'ComponentSectionsCommonSection' } & {
              ' $fragmentRefs'?: { Common_SectionFragment: Common_SectionFragment };
            })
          | ({ __typename: 'ComponentSectionsProjectDeliverySection' } & {
              ' $fragmentRefs'?: { Project_Delivery_SectionFragment: Project_Delivery_SectionFragment };
            })
          | ({ __typename: 'ComponentSectionsProjectEvaluationSection' } & {
              ' $fragmentRefs'?: { Project_Evaluation_SectionFragment: Project_Evaluation_SectionFragment };
            })
          | ({ __typename: 'ComponentSectionsProjectPlanSection' } & {
              ' $fragmentRefs'?: { Project_Plan_SectionFragment: Project_Plan_SectionFragment };
            })
          | { __typename: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type GetResourceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetResourceQuery = {
  __typename?: 'Query';
  resource?: {
    __typename?: 'ResourceEntityResponse';
    data?: {
      __typename?: 'ResourceEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Resource';
        name: string;
        description?: string | null;
        slug: string;
        gallery?:
          | ({ __typename?: 'ComponentSharedGallery' } & { ' $fragmentRefs'?: { GalleryFragment: GalleryFragment } })
          | null;
        level?:
          | ({ __typename?: 'ComponentContentLevel' } & { ' $fragmentRefs'?: { LevelFragment: LevelFragment } })
          | null;
        subjects?:
          | ({ __typename?: 'SubjectRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_SubjectsFragment: Related_SubjectsFragment };
            })
          | null;
        tags?:
          | ({ __typename?: 'TagRelationResponseCollection' } & {
              ' $fragmentRefs'?: { Related_TagsFragment: Related_TagsFragment };
            })
          | null;
        linkedItems?: Array<
          | {
              __typename: 'ComponentItemsLinkedChallenges';
              challenges?:
                | ({ __typename?: 'ChallengeRelationResponseCollection' } & {
                    ' $fragmentRefs'?: { Challenges_OverviewFragment: Challenges_OverviewFragment };
                  })
                | null;
            }
          | {
              __typename: 'ComponentItemsLinkedLessons';
              lessons?:
                | ({ __typename?: 'LessonRelationResponseCollection' } & {
                    ' $fragmentRefs'?: { Lessons_OverviewFragment: Lessons_OverviewFragment };
                  })
                | null;
            }
          | {
              __typename: 'ComponentItemsLinkedProjects';
              projects?:
                | ({ __typename?: 'ProjectRelationResponseCollection' } & {
                    ' $fragmentRefs'?: { Projects_OverviewFragment: Projects_OverviewFragment };
                  })
                | null;
            }
          | { __typename: 'Error' }
          | null
        > | null;
        sections?: Array<
          | ({ __typename: 'ComponentSectionsCommonSection' } & {
              ' $fragmentRefs'?: { Common_SectionFragment: Common_SectionFragment };
            })
          | { __typename: 'Error' }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type GetRoomsListQueryVariables = Exact<{ [key: string]: never }>;

export type GetRoomsListQuery = {
  __typename?: 'Query';
  rooms?: {
    __typename?: 'RoomEntityResponseCollection';
    data: Array<{
      __typename?: 'RoomEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Room';
        name: string;
        slug: string;
        description?: string | null;
        enabled?: boolean | null;
        isTopLevel?: boolean | null;
      } | null;
    }>;
  } | null;
};

export type GetRoomDataQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  userFilters?: InputMaybe<Scalars['JSON']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetRoomDataQuery = {
  __typename?: 'Query';
  roomEntry?: {
    __typename?: 'RoomEntryResponse';
    data?: {
      __typename?: 'RoomEntry';
      id?: string | null;
      name?: string | null;
      slug?: string | null;
      enabled?: boolean | null;
      enablePagination?: boolean | null;
      pageSize?: number | null;
      isTopLevel?: boolean | null;
      description?: string | null;
      showHeading?: boolean | null;
      projects?: {
        __typename?: 'ProjectEntityResponseCollection';
        data: Array<{
          __typename: 'ProjectEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'Project';
            name: string;
            slug: string;
            updatedAt?: any | null;
            ordering?: number | null;
            preview?:
              | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
              | null;
            preparationDuration?:
              | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
                  ' $fragmentRefs'?: { DurationFragment: DurationFragment };
                })
              | null;
            learningDuration?:
              | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
                  ' $fragmentRefs'?: { DurationFragment: DurationFragment };
                })
              | null;
            subjects?:
              | ({ __typename?: 'SubjectRelationResponseCollection' } & {
                  ' $fragmentRefs'?: { Related_SubjectsFragment: Related_SubjectsFragment };
                })
              | null;
            grades?:
              | ({ __typename?: 'GradeRelationResponseCollection' } & {
                  ' $fragmentRefs'?: { Related_GradesFragment: Related_GradesFragment };
                })
              | null;
            tags?:
              | ({ __typename?: 'TagRelationResponseCollection' } & {
                  ' $fragmentRefs'?: { Related_TagsFragment: Related_TagsFragment };
                })
              | null;
          } | null;
        }>;
      } | null;
      lessons?: {
        __typename?: 'LessonEntityResponseCollection';
        data: Array<{
          __typename: 'LessonEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'Lesson';
            name: string;
            slug: string;
            updatedAt?: any | null;
            ordering?: number | null;
            preview?:
              | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
              | null;
            preparationDuration?:
              | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
                  ' $fragmentRefs'?: { DurationFragment: DurationFragment };
                })
              | null;
            learningDuration?:
              | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
                  ' $fragmentRefs'?: { DurationFragment: DurationFragment };
                })
              | null;
            subjects?:
              | ({ __typename?: 'SubjectRelationResponseCollection' } & {
                  ' $fragmentRefs'?: { Related_SubjectsFragment: Related_SubjectsFragment };
                })
              | null;
            grades?:
              | ({ __typename?: 'GradeRelationResponseCollection' } & {
                  ' $fragmentRefs'?: { Related_GradesFragment: Related_GradesFragment };
                })
              | null;
            tags?:
              | ({ __typename?: 'TagRelationResponseCollection' } & {
                  ' $fragmentRefs'?: { Related_TagsFragment: Related_TagsFragment };
                })
              | null;
          } | null;
        }>;
      } | null;
      activities?: {
        __typename?: 'ActivityEntityResponseCollection';
        data: Array<{
          __typename: 'ActivityEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'Activity';
            name: string;
            slug: string;
            updatedAt?: any | null;
            ordering?: number | null;
            preview?:
              | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
              | null;
            preparationDuration?:
              | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
                  ' $fragmentRefs'?: { DurationFragment: DurationFragment };
                })
              | null;
            learningDuration?:
              | ({ __typename?: 'ComponentAtomsTimeSelector' } & {
                  ' $fragmentRefs'?: { DurationFragment: DurationFragment };
                })
              | null;
            subjects?:
              | ({ __typename?: 'SubjectRelationResponseCollection' } & {
                  ' $fragmentRefs'?: { Related_SubjectsFragment: Related_SubjectsFragment };
                })
              | null;
            grades?:
              | ({ __typename?: 'GradeRelationResponseCollection' } & {
                  ' $fragmentRefs'?: { Related_GradesFragment: Related_GradesFragment };
                })
              | null;
            tags?:
              | ({ __typename?: 'TagRelationResponseCollection' } & {
                  ' $fragmentRefs'?: { Related_TagsFragment: Related_TagsFragment };
                })
              | null;
          } | null;
        }>;
      } | null;
      resources?: {
        __typename?: 'ResourceEntityResponseCollection';
        data: Array<{
          __typename: 'ResourceEntity';
          id?: string | null;
          attributes?: {
            __typename?: 'Resource';
            name: string;
            slug: string;
            updatedAt?: any | null;
            ordering?: number | null;
            preview?:
              | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
              | null;
            subjects?:
              | ({ __typename?: 'SubjectRelationResponseCollection' } & {
                  ' $fragmentRefs'?: { Related_SubjectsFragment: Related_SubjectsFragment };
                })
              | null;
            tags?:
              | ({ __typename?: 'TagRelationResponseCollection' } & {
                  ' $fragmentRefs'?: { Related_TagsFragment: Related_TagsFragment };
                })
              | null;
          } | null;
        }>;
      } | null;
      challenges?: {
        __typename?: 'ChallengeEntityResponseCollection';
        data: Array<{ __typename: 'ChallengeEntity'; id?: string | null }>;
      } | null;
    } | null;
  } | null;
};

export type GetRoomFiltersQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type GetRoomFiltersQuery = {
  __typename?: 'Query';
  roomFilters?: {
    __typename?: 'RoomEntityResponse';
    data?: {
      __typename?: 'RoomEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Room';
        name: string;
        slug: string;
        enabled?: boolean | null;
        allowedFilters: Array<
          | {
              __typename: 'ComponentFiltersComplexityFilter';
              id: string;
              enabled?: boolean | null;
              editable?: boolean | null;
              title?: string | null;
              multiple?: boolean | null;
              complexities?: {
                __typename?: 'ComplexityRelationResponseCollection';
                data: Array<{
                  __typename?: 'ComplexityEntity';
                  id?: string | null;
                  attributes?: { __typename?: 'Complexity'; name: string; ordering: number } | null;
                }>;
              } | null;
            }
          | {
              __typename: 'ComponentFiltersContentTypeFilter';
              id: string;
              enabled?: boolean | null;
              editable?: boolean | null;
              title?: string | null;
              multiple?: boolean | null;
              showProjects?: boolean | null;
              showLessons?: boolean | null;
              showActivities?: boolean | null;
              showResources?: boolean | null;
              showChallenges?: boolean | null;
            }
          | {
              __typename: 'ComponentFiltersDurationFilter';
              id: string;
              enabled?: boolean | null;
              editable?: boolean | null;
              title?: string | null;
              multiple?: boolean | null;
              durations: Array<{
                __typename?: 'ComponentHelpersDurationFilterTimeSelector';
                id: string;
                title: string;
                fromHours?: number | null;
                fromMinutes: number;
                toHours: number;
                toMinutes: number;
              } | null>;
            }
          | {
              __typename: 'ComponentFiltersFavoriteFilter';
              id: string;
              enabled?: boolean | null;
              editable?: boolean | null;
              title?: string | null;
              multiple?: boolean | null;
            }
          | {
              __typename: 'ComponentFiltersGradesFilter';
              id: string;
              enabled?: boolean | null;
              editable?: boolean | null;
              title?: string | null;
              multiple?: boolean | null;
              grades?: {
                __typename?: 'GradeRelationResponseCollection';
                data: Array<{
                  __typename?: 'GradeEntity';
                  id?: string | null;
                  attributes?: { __typename?: 'Grade'; name: string; ordering?: number | null } | null;
                }>;
              } | null;
            }
          | {
              __typename: 'ComponentFiltersOwnerFilter';
              id: string;
              enabled?: boolean | null;
              editable?: boolean | null;
              title?: string | null;
              multiple?: boolean | null;
            }
          | {
              __typename: 'ComponentFiltersSharedFilter';
              id: string;
              enabled?: boolean | null;
              editable?: boolean | null;
              title?: string | null;
              multiple?: boolean | null;
              showSharedByMe?: boolean | null;
              showSharedWithMe?: boolean | null;
            }
          | {
              __typename: 'ComponentFiltersSubjectsFilter';
              id: string;
              enabled?: boolean | null;
              editable?: boolean | null;
              title?: string | null;
              multiple?: boolean | null;
              subjects?: {
                __typename?: 'SubjectRelationResponseCollection';
                data: Array<{
                  __typename?: 'SubjectEntity';
                  id?: string | null;
                  attributes?: { __typename?: 'Subject'; name: string; ordering?: number | null; code: string } | null;
                }>;
              } | null;
            }
          | {
              __typename: 'ComponentFiltersSubscriptionsFilter';
              id: string;
              enabled?: boolean | null;
              editable?: boolean | null;
              title?: string | null;
              multiple?: boolean | null;
              subscriptions?: {
                __typename?: 'SubscriptionRelationResponseCollection';
                data: Array<{
                  __typename?: 'SubscriptionEntity';
                  id?: string | null;
                  attributes?: { __typename?: 'Subscription'; name: string; publishedAt?: any | null } | null;
                }>;
              } | null;
            }
          | {
              __typename: 'ComponentFiltersTagsFilter';
              id: string;
              enabled?: boolean | null;
              editable?: boolean | null;
              title?: string | null;
              multiple?: boolean | null;
              tags?: {
                __typename?: 'TagRelationResponseCollection';
                data: Array<{
                  __typename?: 'TagEntity';
                  id?: string | null;
                  attributes?: { __typename?: 'Tag'; name: string; slug: string } | null;
                }>;
              } | null;
            }
          | { __typename: 'Error' }
          | null
        >;
      } | null;
    } | null;
  } | null;
};

export type SearchQueryVariables = Exact<{
  query: Scalars['String']['input'];
}>;

export type SearchQuery = {
  __typename?: 'Query';
  search?: {
    __typename?: 'SearchResponse';
    lessons?: {
      __typename?: 'LessonEntityResponseCollection';
      data: Array<{
        __typename: 'LessonEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'Lesson';
          name: string;
          description?: string | null;
          preview?:
            | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
            | null;
        } | null;
      }>;
    } | null;
    projects?: {
      __typename?: 'ProjectEntityResponseCollection';
      data: Array<{
        __typename: 'ProjectEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'Project';
          name: string;
          description?: string | null;
          preview?:
            | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
            | null;
        } | null;
      }>;
    } | null;
    activities?: {
      __typename?: 'ActivityEntityResponseCollection';
      data: Array<{
        __typename: 'ActivityEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'Activity';
          name: string;
          description?: string | null;
          preview?:
            | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
            | null;
        } | null;
      }>;
    } | null;
    resources?: {
      __typename?: 'ResourceEntityResponseCollection';
      data: Array<{
        __typename: 'ResourceEntity';
        id?: string | null;
        attributes?: {
          __typename?: 'Resource';
          name: string;
          description?: string | null;
          preview?:
            | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
            | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export type GetSectionsDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSectionsDetailsQuery = {
  __typename?: 'Query';
  sectionsDetails?: {
    __typename?: 'SectionsDetailsResponseCollection';
    data: Array<{
      __typename?: 'SectionDetailsEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'SectionDetails';
        name: string;
        externalId?: string | null;
        studentsCount?: number | null;
        createdAt?: any | null;
        enabled?: boolean | null;
      } | null;
    } | null>;
  } | null;
};

export type GetSectionsQueryVariables = Exact<{
  filters?: InputMaybe<SectionFiltersInput>;
  studentsPagination?: InputMaybe<PaginationArg>;
}>;

export type GetSectionsQuery = {
  __typename?: 'Query';
  sections?: {
    __typename?: 'SectionEntityResponseCollection';
    data: Array<{
      __typename?: 'SectionEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Section';
        name: string;
        enabled?: boolean | null;
        externalId?: string | null;
        parentSection?: {
          __typename?: 'SectionEntityResponse';
          data?: {
            __typename?: 'SectionEntity';
            id?: string | null;
            attributes?: { __typename?: 'Section'; name: string; externalId?: string | null } | null;
          } | null;
        } | null;
        students?: {
          __typename?: 'UsersPermissionsUserRelationResponseCollection';
          data: Array<{
            __typename?: 'UsersPermissionsUserEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UsersPermissionsUser';
              name?: string | null;
              externalId?: string | null;
              email: string;
              username: string;
            } | null;
          }>;
        } | null;
        teachers?: {
          __typename?: 'UsersPermissionsUserRelationResponseCollection';
          data: Array<{
            __typename?: 'UsersPermissionsUserEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'UsersPermissionsUser';
              name?: string | null;
              externalId?: string | null;
              email: string;
              username: string;
            } | null;
          }>;
        } | null;
        grades?: {
          __typename?: 'GradeRelationResponseCollection';
          data: Array<{
            __typename?: 'GradeEntity';
            id?: string | null;
            attributes?: { __typename?: 'Grade'; name: string } | null;
          }>;
        } | null;
        subjects?: {
          __typename?: 'SubjectRelationResponseCollection';
          data: Array<{
            __typename?: 'SubjectEntity';
            id?: string | null;
            attributes?: { __typename?: 'Subject'; name: string } | null;
          }>;
        } | null;
        owner?: {
          __typename?: 'UsersPermissionsUserEntityResponse';
          data?: {
            __typename?: 'UsersPermissionsUserEntity';
            id?: string | null;
            attributes?: { __typename?: 'UsersPermissionsUser'; name?: string | null } | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetTutorialQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetTutorialQuery = {
  __typename?: 'Query';
  tutorial?: {
    __typename?: 'TutorialEntityResponse';
    data?: {
      __typename?: 'TutorialEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'Tutorial';
        title: string;
        collapsible: boolean;
        editorType: Enum_Tutorial_Editortype;
        mainImage: { __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } };
        steps?: Array<
          | {
              __typename: 'ComponentItemsTutorialImageStep';
              id: string;
              baseFields: { __typename?: 'ComponentItemsTutorialStepCommonFields' } & {
                ' $fragmentRefs'?: { Tutorial_Step_Common_FieldsFragment: Tutorial_Step_Common_FieldsFragment };
              };
              image?:
                | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
                | null;
              tutorialStepAvailableActions?: Array<
                | ({ __typename?: 'ComponentItemsTutorialStepAvailableActions' } & {
                    ' $fragmentRefs'?: {
                      With_Tutorial_Step_Available_ActionsFragment: With_Tutorial_Step_Available_ActionsFragment;
                    };
                  })
                | null
              > | null;
              tutorialStepNeededActions?: Array<
                | ({ __typename?: 'ComponentItemsTutorialStepNeededActions' } & {
                    ' $fragmentRefs'?: {
                      With_Tutorial_Step_Needed_ActionsFragment: With_Tutorial_Step_Needed_ActionsFragment;
                    };
                  })
                | null
              > | null;
            }
          | {
              __typename: 'ComponentItemsTutorialTextStep';
              id: string;
              baseFields: { __typename?: 'ComponentItemsTutorialStepCommonFields' } & {
                ' $fragmentRefs'?: { Tutorial_Step_Common_FieldsFragment: Tutorial_Step_Common_FieldsFragment };
              };
              tutorialStepAvailableActions?: Array<
                | ({ __typename?: 'ComponentItemsTutorialStepAvailableActions' } & {
                    ' $fragmentRefs'?: {
                      With_Tutorial_Step_Available_ActionsFragment: With_Tutorial_Step_Available_ActionsFragment;
                    };
                  })
                | null
              > | null;
              tutorialStepNeededActions?: Array<
                | ({ __typename?: 'ComponentItemsTutorialStepNeededActions' } & {
                    ' $fragmentRefs'?: {
                      With_Tutorial_Step_Needed_ActionsFragment: With_Tutorial_Step_Needed_ActionsFragment;
                    };
                  })
                | null
              > | null;
            }
          | {
              __typename: 'ComponentItemsTutorialVideoStep';
              id: string;
              baseFields: { __typename?: 'ComponentItemsTutorialStepCommonFields' } & {
                ' $fragmentRefs'?: { Tutorial_Step_Common_FieldsFragment: Tutorial_Step_Common_FieldsFragment };
              };
              startImage?:
                | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
                | null;
              endImage?:
                | ({ __typename?: 'UploadFileEntityResponse' } & { ' $fragmentRefs'?: { FileFragment: FileFragment } })
                | null;
              videos: { __typename?: 'UploadFileRelationResponseCollection' } & {
                ' $fragmentRefs'?: { FilesFragment: FilesFragment };
              };
              tutorialStepAvailableActions?: Array<
                | ({ __typename?: 'ComponentItemsTutorialStepAvailableActions' } & {
                    ' $fragmentRefs'?: {
                      With_Tutorial_Step_Available_ActionsFragment: With_Tutorial_Step_Available_ActionsFragment;
                    };
                  })
                | null
              > | null;
              tutorialStepNeededActions?: Array<
                | ({ __typename?: 'ComponentItemsTutorialStepNeededActions' } & {
                    ' $fragmentRefs'?: {
                      With_Tutorial_Step_Needed_ActionsFragment: With_Tutorial_Step_Needed_ActionsFragment;
                    };
                  })
                | null
              > | null;
            }
          | { __typename: 'Error'; code: string; message?: string | null }
          | null
        > | null;
      } | null;
    } | null;
  } | null;
};

export type GetTutorialsCategoriesListQueryVariables = Exact<{ [key: string]: never }>;

export type GetTutorialsCategoriesListQuery = {
  __typename?: 'Query';
  tutorialCategories?: {
    __typename?: 'TutorialCategoryEntityResponseCollection';
    data: Array<{
      __typename?: 'TutorialCategoryEntity';
      id?: string | null;
      attributes?: {
        __typename?: 'TutorialCategory';
        title: string;
        order: number;
        description?: string | null;
        tutorials?: {
          __typename?: 'TutorialRelationResponseCollection';
          data: Array<{
            __typename?: 'TutorialEntity';
            id?: string | null;
            attributes?: {
              __typename?: 'Tutorial';
              title: string;
              editorType: Enum_Tutorial_Editortype;
              mainImage: { __typename?: 'UploadFileEntityResponse' } & {
                ' $fragmentRefs'?: { FileFragment: FileFragment };
              };
            } | null;
          }>;
        } | null;
      } | null;
    }>;
  } | null;
};

export type LoginMutationVariables = Exact<{
  identifier: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'UsersPermissionsLoginPayload';
    jwt?: string | null;
    user: {
      __typename?: 'UsersPermissionsMe';
      id: string;
      username: string;
      blocked?: boolean | null;
      confirmed?: boolean | null;
      email?: string | null;
      role?: { __typename?: 'UsersPermissionsMeRole'; name: string } | null;
    };
  };
};

export type TestLoginMutationVariables = Exact<{
  type: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type TestLoginMutation = {
  __typename?: 'Mutation';
  testLogin: {
    __typename?: 'UsersPermissionsLoginPayload';
    jwt?: string | null;
    user: {
      __typename?: 'UsersPermissionsMe';
      email?: string | null;
      id: string;
      username: string;
      confirmed?: boolean | null;
      blocked?: boolean | null;
      role?: { __typename?: 'UsersPermissionsMeRole'; name: string; type?: string | null } | null;
    };
  };
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type ForgotPasswordMutation = {
  __typename?: 'Mutation';
  forgotPassword?: { __typename?: 'UsersPermissionsPasswordPayload'; ok: boolean } | null;
};

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword?: {
    __typename?: 'UsersPermissionsLoginPayload';
    jwt?: string | null;
    user: {
      __typename?: 'UsersPermissionsMe';
      id: string;
      username: string;
      email?: string | null;
      role?: { __typename?: 'UsersPermissionsMeRole'; name: string } | null;
    };
  } | null;
};

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  changePassword?: {
    __typename?: 'UsersPermissionsLoginPayload';
    jwt?: string | null;
    user: { __typename?: 'UsersPermissionsMe'; id: string; username: string; email?: string | null };
  } | null;
};

export const ActionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ACTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsAction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actionTitle' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ActionFragment, unknown>;
export const GoalsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GOALS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentContentLearningGoals' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }] },
    },
  ],
} as unknown as DocumentNode<GoalsFragment, unknown>;
export const LevelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LEVEL' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentContentLevel' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }] },
    },
  ],
} as unknown as DocumentNode<LevelFragment, unknown>;
export const MaterialsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MATERIALS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentContentRequireMaterials' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }] },
    },
  ],
} as unknown as DocumentNode<MaterialsFragment, unknown>;
export const FileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FileFragment, unknown>;
export const DurationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DurationFragment, unknown>;
export const Projects_OverviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECTS_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Projects_OverviewFragment, unknown>;
export const Lessons_OverviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LESSONS_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LessonRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Lessons_OverviewFragment, unknown>;
export const Activities_OverviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ACTIVITIES_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Activities_OverviewFragment, unknown>;
export const Resources_OverviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RESOURCES_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Resources_OverviewFragment, unknown>;
export const CollectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'COLLECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentDashboardCollections' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tag' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attributes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projects' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PROJECTS_OVERVIEW' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lessons' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LESSONS_OVERVIEW' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'activities' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'ACTIVITIES_OVERVIEW' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'resources' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RESOURCES_OVERVIEW' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECTS_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LESSONS_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LessonRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ACTIVITIES_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RESOURCES_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionFragment, unknown>;
export const Challenges_OverviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CHALLENGES_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChallengeRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Challenges_OverviewFragment, unknown>;
export const Project_OverviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECT_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Project_OverviewFragment, unknown>;
export const Related_GradesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_GRADES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GradeRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Related_GradesFragment, unknown>;
export const Toc_EntryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TOC_ENTRY' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentItemsToCEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Toc_EntryFragment, unknown>;
export const With_Tutorial_Step_Available_ActionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WITH_TUTORIAL_STEP_AVAILABLE_ACTIONS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentItemsTutorialStepAvailableActions' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<With_Tutorial_Step_Available_ActionsFragment, unknown>;
export const With_Tutorial_Step_Needed_ActionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WITH_TUTORIAL_STEP_NEEDED_ACTIONS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentItemsTutorialStepNeededActions' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<With_Tutorial_Step_Needed_ActionsFragment, unknown>;
export const Tutorial_Step_Common_FieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TUTORIAL_STEP_COMMON_FIELDS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentItemsTutorialStepCommonFields' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showProgress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showOverlay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeDelay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backButtonText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backButtonAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backButtonUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextButtonText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextButtonAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextButtonUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Tutorial_Step_Common_FieldsFragment, unknown>;
export const Linkable_ListFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LINKABLE_LIST' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsLinkableList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Linkable_ListFragment, unknown>;
export const FilesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FilesFragment, unknown>;
export const Common_SectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'COMMON_SECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSectionsCommonSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enumerable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preparationDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LINKABLE_LIST' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsLinkableList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Common_SectionFragment, unknown>;
export const Lesson_Plan_SectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LESSON_PLAN_SECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSectionsLessonPlanSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enumerable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preparationDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LINKABLE_LIST' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsLinkableList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Lesson_Plan_SectionFragment, unknown>;
export const Lesson_Run_SectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LESSON_RUN_SECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSectionsLessonRunSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enumerable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preparationDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LINKABLE_LIST' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsLinkableList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Lesson_Run_SectionFragment, unknown>;
export const Project_Delivery_SectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECT_DELIVERY_SECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSectionsProjectDeliverySection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enumerable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preparationDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LINKABLE_LIST' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsLinkableList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Project_Delivery_SectionFragment, unknown>;
export const Project_Evaluation_SectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECT_EVALUATION_SECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSectionsProjectEvaluationSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enumerable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preparationDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LINKABLE_LIST' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsLinkableList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Project_Evaluation_SectionFragment, unknown>;
export const Project_Plan_SectionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECT_PLAN_SECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSectionsProjectPlanSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enumerable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preparationDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LINKABLE_LIST' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsLinkableList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Project_Plan_SectionFragment, unknown>;
export const GalleryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GALLERY' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSharedGallery' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'main' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GalleryFragment, unknown>;
export const Related_SubjectsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_SUBJECTS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubjectRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Related_SubjectsFragment, unknown>;
export const Related_TagsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_TAGS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TagRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Related_TagsFragment, unknown>;
export const Full_Editor_ProjectFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FULL_EDITOR_PROJECT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditorProjectEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'editorState' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Full_Editor_ProjectFragment, unknown>;
export const Short_Editor_ProjectFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SHORT_EDITOR_PROJECT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditorProjectEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<Short_Editor_ProjectFragment, unknown>;
export const GetFeatureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFeature' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFeatureQuery, GetFeatureQueryVariables>;
export const GetFeatureByNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFeatureByName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'name' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pagination' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageCount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFeatureByNameQuery, GetFeatureByNameQueryVariables>;
export const GetFeaturesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFeatures' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'features' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pagination' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageCount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFeaturesQuery, GetFeaturesQueryVariables>;
export const GetActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gallery' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GALLERY' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goals' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GOALS' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'materials' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MATERIALS' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'level' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LEVEL' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'preparationDuration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'learningDuration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'grades' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_GRADES' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subjects' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_SUBJECTS' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tags' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_TAGS' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PROJECT_OVERVIEW' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'linkedItems' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentItemsLinkedProjects' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'projects' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PROJECTS_OVERVIEW' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentItemsLinkedLessons' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lessons' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'LESSONS_OVERVIEW' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentItemsLinkedChallenges' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'challenges' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'CHALLENGES_OVERVIEW' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sections' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentSectionsCommonSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'COMMON_SECTION' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LINKABLE_LIST' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsLinkableList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GALLERY' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSharedGallery' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'main' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GOALS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentContentLearningGoals' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MATERIALS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentContentRequireMaterials' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LEVEL' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentContentLevel' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_GRADES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GradeRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_SUBJECTS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubjectRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_TAGS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TagRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECT_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECTS_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LESSONS_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LessonRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CHALLENGES_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChallengeRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'COMMON_SECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSectionsCommonSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enumerable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preparationDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetActivityQuery, GetActivityQueryVariables>;
export const GetWebAppSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetWebAppSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'appsSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'key' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'StringValue', value: 'frontend_app', block: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'settings' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetWebAppSettingsQuery, GetWebAppSettingsQueryVariables>;
export const GetDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDashboard' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'greeting' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'libraryAction' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ACTION' } }],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'communityAction' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ACTION' } }],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pdAction' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ACTION' } }],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'helpAction' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ACTION' } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'collections' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'COLLECTION' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inspiration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'inspirationAction' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'ACTION' } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECTS_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LESSONS_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LessonRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ACTIVITIES_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RESOURCES_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ACTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsAction' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'actionTitle' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'COLLECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentDashboardCollections' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tag' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attributes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projects' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PROJECTS_OVERVIEW' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lessons' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LESSONS_OVERVIEW' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'activities' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'ACTIVITIES_OVERVIEW' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'resources' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RESOURCES_OVERVIEW' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDashboardQuery, GetDashboardQueryVariables>;
export const EditorProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EditorProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editorProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FULL_EDITOR_PROJECT' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FULL_EDITOR_PROJECT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditorProjectEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'editorState' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditorProjectQuery, EditorProjectQueryVariables>;
export const SearchEditorProjectsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchEditorProjects' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'EditorProjectFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationArg' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editorProjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SHORT_EDITOR_PROJECT' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'meta' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pagination' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pageCount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SHORT_EDITOR_PROJECT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditorProjectEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchEditorProjectsQuery, SearchEditorProjectsQueryVariables>;
export const CreateEditorProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEditorProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EditorProjectInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createEditorProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SHORT_EDITOR_PROJECT' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SHORT_EDITOR_PROJECT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditorProjectEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateEditorProjectMutation, CreateEditorProjectMutationVariables>;
export const UpdateEditorProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEditorProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'EditorProjectInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateEditorProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SHORT_EDITOR_PROJECT' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SHORT_EDITOR_PROJECT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditorProjectEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateEditorProjectMutation, UpdateEditorProjectMutationVariables>;
export const DeleteEditorProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteEditorProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteEditorProject' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'SHORT_EDITOR_PROJECT' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SHORT_EDITOR_PROJECT' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'EditorProjectEntity' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previewImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteEditorProjectMutation, DeleteEditorProjectMutationVariables>;
export const UserEditorProjectsCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserEditorProjectsCount' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userEditorProjectsCount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'data' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserEditorProjectsCountQuery, UserEditorProjectsCountQueryVariables>;
export const GetLessonDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLesson' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lesson' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gallery' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GALLERY' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goals' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GOALS' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'materials' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MATERIALS' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'level' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LEVEL' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'preparationDuration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'learningDuration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'grades' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_GRADES' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subjects' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_SUBJECTS' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tags' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_TAGS' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'project' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PROJECT_OVERVIEW' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'linkedItems' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentItemsLinkedProjects' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'projects' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PROJECTS_OVERVIEW' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentItemsLinkedLessons' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lessons' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'LESSONS_OVERVIEW' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentItemsLinkedChallenges' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'challenges' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'CHALLENGES_OVERVIEW' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sections' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentSectionsCommonSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'COMMON_SECTION' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentSectionsLessonPlanSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'LESSON_PLAN_SECTION' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentSectionsLessonRunSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LESSON_RUN_SECTION' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LINKABLE_LIST' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsLinkableList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GALLERY' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSharedGallery' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'main' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GOALS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentContentLearningGoals' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MATERIALS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentContentRequireMaterials' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LEVEL' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentContentLevel' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_GRADES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GradeRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_SUBJECTS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubjectRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_TAGS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TagRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECT_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECTS_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LESSONS_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LessonRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CHALLENGES_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChallengeRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'COMMON_SECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSectionsCommonSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enumerable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preparationDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LESSON_PLAN_SECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSectionsLessonPlanSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enumerable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preparationDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LESSON_RUN_SECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSectionsLessonRunSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enumerable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preparationDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLessonQuery, GetLessonQueryVariables>;
export const GetLibraryDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLibraryData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeProjects' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeLessons' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeActivities' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeResources' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'projectFilters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lessonFilters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'LessonFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'activityFilters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityFiltersInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'resourceFilters' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceFiltersInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'projectFilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'start' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeProjects' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'preview' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'preparationDuration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'learningDuration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subjects' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'sort' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      { kind: 'StringValue', value: 'ordering:asc', block: false },
                                      { kind: 'StringValue', value: 'name:asc', block: false },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_SUBJECTS' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'grades' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'sort' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      { kind: 'StringValue', value: 'ordering:asc', block: false },
                                      { kind: 'StringValue', value: 'name:asc', block: false },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_GRADES' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tags' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_TAGS' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lessons' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lessonFilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'start' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeLessons' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'preview' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'preparationDuration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'learningDuration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subjects' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'sort' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      { kind: 'StringValue', value: 'ordering:asc', block: false },
                                      { kind: 'StringValue', value: 'name:asc', block: false },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_SUBJECTS' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'grades' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'sort' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      { kind: 'StringValue', value: 'ordering:asc', block: false },
                                      { kind: 'StringValue', value: 'name:asc', block: false },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_GRADES' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tags' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_TAGS' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'activityFilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'start' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeActivities' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'preview' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'preparationDuration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'learningDuration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subjects' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'sort' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      { kind: 'StringValue', value: 'ordering:asc', block: false },
                                      { kind: 'StringValue', value: 'name:asc', block: false },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_SUBJECTS' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'grades' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'sort' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      { kind: 'StringValue', value: 'ordering:asc', block: false },
                                      { kind: 'StringValue', value: 'name:asc', block: false },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_GRADES' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tags' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_TAGS' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resources' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'resourceFilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'start' },
                      value: { kind: 'IntValue', value: '0' },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '100' },
                    },
                  ],
                },
              },
            ],
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: { kind: 'Variable', name: { kind: 'Name', value: 'includeResources' } },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'preview' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subjects' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'sort' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      { kind: 'StringValue', value: 'ordering:asc', block: false },
                                      { kind: 'StringValue', value: 'name:asc', block: false },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_SUBJECTS' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tags' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_TAGS' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_SUBJECTS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubjectRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_GRADES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GradeRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_TAGS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TagRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLibraryDataQuery, GetLibraryDataQueryVariables>;
export const GetLibraryFiltersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLibraryFilters' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subjects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'StringValue', value: 'ordering:asc', block: false },
                    { kind: 'StringValue', value: 'name:asc', block: false },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'grades' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'StringValue', value: 'ordering:asc', block: false },
                    { kind: 'StringValue', value: 'name:asc', block: false },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLibraryFiltersQuery, GetLibraryFiltersQueryVariables>;
export const MeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Me' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MeQuery, MeQueryVariables>;
export const GetPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'workspace' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'blocks' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentSharedRichText' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'body' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentSharedGallery' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GALLERY' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentSectionsCommonSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'COMMON_SECTION' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentSharedMedia' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'file' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentDashboardCollections' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'COLLECTION' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LINKABLE_LIST' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsLinkableList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECTS_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LESSONS_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LessonRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ACTIVITIES_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ActivityRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RESOURCES_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ResourceRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GALLERY' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSharedGallery' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'main' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'COMMON_SECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSectionsCommonSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enumerable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preparationDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'COLLECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentDashboardCollections' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'limit' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tag' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attributes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'projects' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PROJECTS_OVERVIEW' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lessons' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LESSONS_OVERVIEW' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'activities' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'ACTIVITIES_OVERVIEW' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'resources' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RESOURCES_OVERVIEW' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPageQuery, GetPageQueryVariables>;
export const GetProjectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'project' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gallery' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GALLERY' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'goals' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GOALS' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'materials' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MATERIALS' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'level' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LEVEL' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'preparationDuration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'learningDuration' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'grades' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_GRADES' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subjects' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_SUBJECTS' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tags' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_TAGS' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lessons' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'LESSONS_OVERVIEW' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tableOfContents' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentItemsToCEntry' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TOC_ENTRY' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'linkedItems' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentItemsLinkedProjects' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'projects' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PROJECTS_OVERVIEW' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentItemsLinkedLessons' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lessons' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'LESSONS_OVERVIEW' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentItemsLinkedChallenges' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'challenges' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'CHALLENGES_OVERVIEW' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sections' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentSectionsCommonSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'COMMON_SECTION' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentSectionsProjectPlanSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'PROJECT_PLAN_SECTION' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentSectionsProjectDeliverySection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'PROJECT_DELIVERY_SECTION' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentSectionsProjectEvaluationSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'PROJECT_EVALUATION_SECTION' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LINKABLE_LIST' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsLinkableList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GALLERY' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSharedGallery' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'main' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GOALS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentContentLearningGoals' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MATERIALS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentContentRequireMaterials' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LEVEL' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentContentLevel' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_GRADES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GradeRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_SUBJECTS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubjectRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_TAGS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TagRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LESSONS_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LessonRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TOC_ENTRY' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentItemsToCEntry' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'entry' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
                { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECTS_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CHALLENGES_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChallengeRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'COMMON_SECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSectionsCommonSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enumerable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preparationDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECT_PLAN_SECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSectionsProjectPlanSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enumerable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preparationDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECT_DELIVERY_SECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSectionsProjectDeliverySection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enumerable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preparationDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECT_EVALUATION_SECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSectionsProjectEvaluationSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enumerable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preparationDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetProjectQuery, GetProjectQueryVariables>;
export const GetResourceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetResource' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resource' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gallery' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'GALLERY' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'level' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LEVEL' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subjects' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_SUBJECTS' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tags' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_TAGS' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'linkedItems' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentItemsLinkedProjects' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'projects' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'PROJECTS_OVERVIEW' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentItemsLinkedLessons' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'lessons' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'LESSONS_OVERVIEW' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentItemsLinkedChallenges' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'challenges' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'CHALLENGES_OVERVIEW' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sections' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentSectionsCommonSection' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'FragmentSpread', name: { kind: 'Name', value: 'COMMON_SECTION' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LINKABLE_LIST' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsLinkableList' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anchor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GALLERY' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSharedGallery' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'main' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'files' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LEVEL' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentContentLevel' } },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'body' } }] },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_SUBJECTS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubjectRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_TAGS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TagRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PROJECTS_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ProjectRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'LESSONS_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'LessonRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preview' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'preparationDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'learningDuration' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CHALLENGES_OVERVIEW' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ChallengeRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'COMMON_SECTION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentSectionsCommonSection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enumerable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buttons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'LINKABLE_LIST' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'preparationDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningDuration' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assets' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetResourceQuery, GetResourceQueryVariables>;
export const GetRoomsListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRoomsList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rooms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isTopLevel' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRoomsListQuery, GetRoomsListQueryVariables>;
export const GetRoomDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRoomData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userFilters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          defaultValue: { kind: 'IntValue', value: '0' },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roomEntry' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userFilters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'userFilters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'enablePagination' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pageSize' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isTopLevel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'showHeading' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'projects' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attributes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'ordering' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'preview' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'preparationDuration' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'learningDuration' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'subjects' },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: { kind: 'Name', value: 'sort' },
                                              value: {
                                                kind: 'ListValue',
                                                values: [
                                                  { kind: 'StringValue', value: 'ordering:asc', block: false },
                                                  { kind: 'StringValue', value: 'name:asc', block: false },
                                                ],
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'RELATED_SUBJECTS' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'grades' },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: { kind: 'Name', value: 'sort' },
                                              value: {
                                                kind: 'ListValue',
                                                values: [
                                                  { kind: 'StringValue', value: 'ordering:asc', block: false },
                                                  { kind: 'StringValue', value: 'name:asc', block: false },
                                                ],
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'RELATED_GRADES' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'tags' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_TAGS' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lessons' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attributes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'ordering' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'preview' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'preparationDuration' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'learningDuration' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'subjects' },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: { kind: 'Name', value: 'sort' },
                                              value: {
                                                kind: 'ListValue',
                                                values: [
                                                  { kind: 'StringValue', value: 'ordering:asc', block: false },
                                                  { kind: 'StringValue', value: 'name:asc', block: false },
                                                ],
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'RELATED_SUBJECTS' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'grades' },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: { kind: 'Name', value: 'sort' },
                                              value: {
                                                kind: 'ListValue',
                                                values: [
                                                  { kind: 'StringValue', value: 'ordering:asc', block: false },
                                                  { kind: 'StringValue', value: 'name:asc', block: false },
                                                ],
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'RELATED_GRADES' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'tags' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_TAGS' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attributes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'ordering' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'preview' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'preparationDuration' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'learningDuration' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DURATION' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'subjects' },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: { kind: 'Name', value: 'sort' },
                                              value: {
                                                kind: 'ListValue',
                                                values: [
                                                  { kind: 'StringValue', value: 'ordering:asc', block: false },
                                                  { kind: 'StringValue', value: 'name:asc', block: false },
                                                ],
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'RELATED_SUBJECTS' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'grades' },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: { kind: 'Name', value: 'sort' },
                                              value: {
                                                kind: 'ListValue',
                                                values: [
                                                  { kind: 'StringValue', value: 'ordering:asc', block: false },
                                                  { kind: 'StringValue', value: 'name:asc', block: false },
                                                ],
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'RELATED_GRADES' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'tags' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_TAGS' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resources' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'attributes' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'ordering' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'preview' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'subjects' },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: { kind: 'Name', value: 'sort' },
                                              value: {
                                                kind: 'ListValue',
                                                values: [
                                                  { kind: 'StringValue', value: 'ordering:asc', block: false },
                                                  { kind: 'StringValue', value: 'name:asc', block: false },
                                                ],
                                              },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'RELATED_SUBJECTS' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'tags' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'RELATED_TAGS' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'challenges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'data' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DURATION' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentAtomsTimeSelector' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'hours' } },
          { kind: 'Field', name: { kind: 'Name', value: 'minutes' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_SUBJECTS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SubjectRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_GRADES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GradeRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RELATED_TAGS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'TagRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRoomDataQuery, GetRoomDataQueryVariables>;
export const GetRoomFiltersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetRoomFilters' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'roomFilters' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'allowedFilters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentFiltersContentTypeFilter' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'editable' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'multiple' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'showProjects' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'showLessons' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'showActivities' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'showResources' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'showChallenges' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentFiltersDurationFilter' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'editable' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'multiple' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'durations' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'fromHours' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'fromMinutes' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'toHours' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'toMinutes' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentFiltersFavoriteFilter' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'editable' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'multiple' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentFiltersGradesFilter' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'editable' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'multiple' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'grades' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'attributes' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                          { kind: 'Field', name: { kind: 'Name', value: 'ordering' } },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentFiltersComplexityFilter' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'editable' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'multiple' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'complexities' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'attributes' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                          { kind: 'Field', name: { kind: 'Name', value: 'ordering' } },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentFiltersOwnerFilter' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'editable' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'multiple' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentFiltersSubjectsFilter' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'editable' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'multiple' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'subjects' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'attributes' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                          { kind: 'Field', name: { kind: 'Name', value: 'ordering' } },
                                                          { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentFiltersSubscriptionsFilter' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'editable' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'multiple' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'subscriptions' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'attributes' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                          {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'publishedAt' },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentFiltersTagsFilter' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'editable' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'multiple' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'tags' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'data' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'attributes' },
                                                      selectionSet: {
                                                        kind: 'SelectionSet',
                                                        selections: [
                                                          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                          { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentFiltersSharedFilter' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'editable' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'multiple' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'showSharedByMe' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'showSharedWithMe' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRoomFiltersQuery, GetRoomFiltersQueryVariables>;
export const SearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Search' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'query' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lessons' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attributes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'preview' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'projects' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attributes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'preview' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activities' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attributes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'preview' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resources' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attributes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'preview' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchQuery, SearchQueryVariables>;
export const GetSectionsDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSectionsDetails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sectionsDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'studentsCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSectionsDetailsQuery, GetSectionsDetailsQueryVariables>;
export const GetSectionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSections' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SectionFiltersInput' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'studentsPagination' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PaginationArg' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentSection' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'students' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'pagination' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'studentsPagination' } },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'teachers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'externalId' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'grades' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subjects' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'owner' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSectionsQuery, GetSectionsQueryVariables>;
export const GetTutorialDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTutorial' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tutorial' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'collapsible' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'editorType' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mainImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } }],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'steps' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentItemsTutorialTextStep' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'baseFields' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TUTORIAL_STEP_COMMON_FIELDS' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'tutorialStepAvailableActions' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'WITH_TUTORIAL_STEP_AVAILABLE_ACTIONS' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'tutorialStepNeededActions' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'WITH_TUTORIAL_STEP_NEEDED_ACTIONS' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentItemsTutorialImageStep' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'baseFields' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TUTORIAL_STEP_COMMON_FIELDS' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'image' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'tutorialStepAvailableActions' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'WITH_TUTORIAL_STEP_AVAILABLE_ACTIONS' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'tutorialStepNeededActions' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'WITH_TUTORIAL_STEP_NEEDED_ACTIONS' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'ComponentItemsTutorialVideoStep' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'baseFields' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'TUTORIAL_STEP_COMMON_FIELDS' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'startImage' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'endImage' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'videos' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILES' } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'tutorialStepAvailableActions' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'WITH_TUTORIAL_STEP_AVAILABLE_ACTIONS' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'tutorialStepNeededActions' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'WITH_TUTORIAL_STEP_NEEDED_ACTIONS' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Error' } },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TUTORIAL_STEP_COMMON_FIELDS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentItemsTutorialStepCommonFields' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showProgress' } },
          { kind: 'Field', name: { kind: 'Name', value: 'showOverlay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timeDelay' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backButtonText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backButtonAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backButtonUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextButtonText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextButtonAction' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nextButtonUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WITH_TUTORIAL_STEP_AVAILABLE_ACTIONS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentItemsTutorialStepAvailableActions' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WITH_TUTORIAL_STEP_NEEDED_ACTIONS' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ComponentItemsTutorialStepNeededActions' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'action' } },
          { kind: 'Field', name: { kind: 'Name', value: 'data' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILES' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileRelationResponseCollection' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTutorialQuery, GetTutorialQueryVariables>;
export const GetTutorialsCategoriesListDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTutorialsCategoriesList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tutorialCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'order' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tutorials' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'attributes' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'editorType' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'mainImage' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FILE' } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FILE' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadFileEntityResponse' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'caption' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alternativeText' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'previewUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formats' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mime' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTutorialsCategoriesListQuery, GetTutorialsCategoriesListQueryVariables>;
export const LoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Login' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'identifier' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'login' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'identifier' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'identifier' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'password' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jwt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'confirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const TestLoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TestLogin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'testLogin' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'password' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jwt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'confirmed' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TestLoginMutation, TestLoginMutationVariables>;
export const ForgotPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ForgotPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forgotPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'ok' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'passwordConfirmation' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'passwordConfirmation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'passwordConfirmation' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jwt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'name' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ChangePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currentPassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'passwordConfirmation' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currentPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currentPassword' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'passwordConfirmation' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'passwordConfirmation' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jwt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ChangePasswordMutation, ChangePasswordMutationVariables>;
